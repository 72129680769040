.aob-footer {
    display: flex;
    flex-direction: column;
    padding: 50px 20px $vs-30;
    border-bottom: 5px solid $cl-prim-light-blue;

    @include media('>=medium') {
        padding: 50px 34px $vs-30;
    }

    @include media('>=large') {
        flex-direction: row;
        flex-wrap: wrap;
        padding: $vs-100 120px;
    }

    @include media('>=xlarge') {
        padding: 250px 220px 30px;
    }

    @include ie-only {
        position: relative;
        background-color: $cl-sec-black;
        z-index: $zi-footer;
    }

    &__brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        margin: 0 auto $vs-60;

        @include media('>=large') {
            display: none;
        }
    }

    &__social-links {
        display: flex;
        justify-content: center;
        margin-bottom: $vs-30;

        @include media('>=large') {
            justify-content: flex-start;
            flex-basis: 50%;
            order: 3;
        }

        @include media('>=xlarge') {
            display: none;
        }
    }

    &__copyright {
        font: normal 12px/14px $acumin-pro-condensed;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.06em;

        @include media('>=medium') {
            max-width: 280px;
            margin: 0 auto;
        }

        @include media('>=large') {
            max-width: none;
            margin: 0;
            flex-basis: calc(50%);
            order: 4;
            padding-left: 20px;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-align: left;

            @include ie-only {
                flex-basis: calc(50% - 20px);
            }
        }

        @include media('>=xlarge') {
            flex-basis: 100%;
            padding: 0;
            text-align: center;
        }
    }
}

.aob-email-signup {
    margin-bottom: $vs-60;

    // TODO: Remove on Phase 2
    @include media('<large') {
        display: none;
    }

    @include media('>=medium') {
        margin-left: auto;
        margin-right: auto;
        max-width: 340px;
    }

    @include media('>=large') {
        order: 1;
        flex-basis: 50%;
        max-width: none;
        margin: 0 0 90px;
        padding-right: 100px;

        @include ie-only {
            flex-basis: calc(50% - 100px);
        }
    }

    @include media('>=xlarge') {
        margin-bottom: $vs-200;
        padding-right: 160px;

        @include ie-only {
            flex-basis: calc(50% - 160px);
        }
    }

    // TODO: Remove on Phase 2
    &__title,
    &__form {
        visibility: hidden;
    }

    &__title {
        margin-bottom: $vs-30;
        font: 900 16px/19px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        text-align: center;

        @include media('>=large') {
            text-align: left;
        }

        @include media('>=xlarge') {
            margin-bottom: $vs-40;
            font-size: 22px;
            line-height: 26px;
        }
    }

    &__form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($cl-prim-light-blue, 0.9);
        padding-bottom: $vs-20;
    }

    &__field,
    &__cta {
        padding: 0;
        border: none;
        background-color: transparent;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $cl-sec-white;
    }

    &__field {
        &::placeholder {
            color: $cl-sec-white;
        }
    }

    &__logo {
        display: none;

        @include media('>=large') {
            display: block;
            width: 160px;
            margin-bottom: 50px;
        }

        @include media('>=xlarge') {
            width: 332px;
            margin-bottom: 80px;
        }
    }
}

@import './footerNavigation';
