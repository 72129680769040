.brands-accordion {
    position: relative;
    padding: 0 20px;
    margin-bottom: $vs-100;
    background-color: $cl-sec-black;
    opacity: 0;
    transition: $animation-duration-default;

    @include media('>=medium') {
        height: 100vh;
        padding: 0 34px;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        overflow: visible;
    }

    @include media('>=large') {
        padding: 0 40px;
    }

    @include media('>=xlarge') {
        padding: 0 90px;
    }

    &--revealed {
        opacity: 1;
    }

    &--shift-left {
        @include media('>=medium') {
            transform: translateX(calc((-100vw + 34px * 2) / 4));

            @include ie-only {
                left: calc((-100vw + 34px * 2) / 4);
            }
        }

        @include media('>=large') {
            transform: translateX(calc((-100vw + 40px * 2) / 4));

            @include ie-only {
                left: calc((-100vw + 40px * 2) / 4);
            }
        }

        @include media('>=xlarge') {
            transform: translateX(calc((-100vw + 90px * 2) / 4));

            @include ie-only {
                left: calc((-100vw + 90px * 2) / 4);
            }
        }
    }

    &__item {
        & + & {
            margin-top: 10px;

            @include media('>=medium') {
                margin-top: 0;
            }
        }
    }
}

.brands-accordion-item {
    position: relative;
    min-height: calc(100vw - 40px);
    background-color: $cl-sec-black;
    overflow: hidden;
    cursor: pointer;

    @include media('>=medium') {
        min-height: 0;
        transition: $animation-duration-default;
    }

    &--expanded {
        padding-bottom: 0;
        border-bottom: 3px solid $cl-prim-light-blue;

        @include media('>=medium') {
            min-width: 50% !important;
            flex-basis: 50% !important;
            border-bottom: none;
        }

        .brands-accordion-item {
            &__hover-line {
                @include media('>=medium') {
                    opacity: 1;
                }
            }

            &__content {
                position: relative;

                @include media('>=medium') {
                    flex-direction: column;
                    padding: 40px 70px 40px 40px;
                }
            }

            &__title {
                max-width: 100%;
                margin-bottom: 20px;
                font-size: 46px;
                line-height: 42px;

                @include media('>=medium') {
                    margin-top: auto;
                    writing-mode: lr;
                    transform: none;
                }

                @include media('>=xlarge') {
                    font-size: 80px;
                    line-height: 82px;
                }
            }

            &__togglable {
                @include media('<medium') {
                    @include vertical-slide--expanded(100vh);

                    transition-duration: $animation-duration-default;
                    transition-delay: 0s;
                }

                @include media('>=medium') {
                    margin-bottom: auto;
                    max-height: none;
                    opacity: 1;
                }
            }

            &__cta {
                order: 4;
                margin-left: auto;
                color: $cl-sec-white;
                opacity: 1;

                @include media('>=medium') {
                    align-self: flex-start;
                    margin-left: 0;
                    transform: none;
                }

                span {
                    @include media('>=medium') {
                        writing-mode: lr;
                    }
                }

                .icon-plus,
                .icon-minus {
                    margin-left: 10px;
                }
            }

            &__cta-collapsed-text {
                display: none;
            }

            &__cta-expanded-text {
                display: inline;
            }
        }
    }

    &:hover {
        .brands-accordion-item {
            &__hover-line {
                @include media('>=large') {
                    opacity: 1;
                }
            }

            &__cta,
            &__title {
                @include media('>=large') {
                    color: $cl-prim-light-blue;
                }
            }

            &__cta {
                opacity: 1;
            }
        }
    }

    &__hover-line {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: none;
        width: 3px;
        background-color: $cl-prim-light-blue;
        opacity: 0;
        transition: opacity $animation-duration-default;

        @include media('>=medium') {
            display: block;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 230px 10px 20px;

        @include media('>=medium') {
            height: 100%;
            align-items: flex-start;
            padding: 20px 10px;
            overflow: hidden;
        }

        @include media('>=large') {
            padding: 20px;
        }

        @include media('>=xlarge') {
            padding: 40px;
        }
    }

    &__background-img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        background-size: cover;
        background-position: center;
        opacity: 0.75;
    }

    &__title {
        order: 1;
        max-width: 60%;
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        transition: font-size $animation-duration-default, color $animation-duration-default;

        @include media('>=medium') {
            max-width: none;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            transition: font-size 0s, color $animation-duration-default;

            @include ie-only {
                margin-right: auto;
                transform: rotate(270deg) translateX(-100%);
                transform-origin: top left;
            }
        }

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__togglable {
        order: 3;
        transition-duration: 100ms;

        @include media('<medium') {
            @include vertical-slide--collapsed;

            transition-delay: -0.2s;
        }

        @include media('>=medium') {
            max-height: 0;
            // minus accordion container paddings & accordion item paddings
            min-width: calc((100vw - 34px * 2) / 2 - 70px - 40px);
            opacity: 0;
            transition-duration: 0s;
        }

        @include media('>=large') {
            min-width: calc((100vw - 40px * 2 ) / 2 - 70px - 40px);
        }

        @include media('>=xlarge') {
            min-width: calc((100vw - 90px * 2) / 2 - 70px - 40px);
        }

        @include ie-only {
            max-width: 100%;
        }
    }

    &__brands {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: -15px;
        margin-bottom: $vs-100;
        width: calc(100% + 30px);
    }

    &__brand {
        flex-basis: 33.3%;
        flex-shrink: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $vs-30;
        padding: 0 15px;

        @include media('>=medium') {
            flex-basis: 25%;
        }

        &--grow {
            flex-basis: 50%;
        }

        &--shrink {
            flex-shrink: 1;

            @include ie-only {
                flex-basis: auto;
            }
        }
    }

    &__brand-img {
        width: 100%;
        max-height: 40px;
        object-fit: contain;

        @include ie-only {
            max-width: 100%;
        }
    }

    &__description {
        margin-bottom: 35px;
        font: normal 12px/17px $acumin-pro;

        @include media('>=xlarge') {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__cta {
        order: 2;
        max-width: 40%;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        color: $cl-prim-light-blue;
        transition: color $animation-duration-default;

        @include media('>=medium') {
            order: 3;
            align-self: flex-end;
            max-width: none;
            transform: rotate(180deg);
            transform-origin: center;

            @include ie-only {
                margin-right: auto;
                transform: rotate(270deg);
                transform-origin: top left;
            }
        }

        @include media('>=large') {
            opacity: 0;
        }

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }

        span {
            @include media('>=medium') {
                writing-mode: vertical-lr;
            }
        }

        .brands-accordion-item {
            &__cta-collapsed-text {
                @include safari-desktop-only {
                    @include media('>=medium') {
                        position: relative;
                        right: 15px;
                    }

                    @include media('>=xlarge') {
                        right: 25px;
                    }
                }
            }
        }

        .icon-plus,
        .icon-minus {
            margin-left: 10px;

            @include media('>=medium') {
                margin: 10px 0 0 0;

                @include ie-only {
                    margin-left: 10px;
                }
            }
        }
    }

    &__cta-expanded-text {
        display: none;
    }
}
