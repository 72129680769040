.page-hero {
    position: relative;

    &__title {
        @include position-absolute-center();

        font: normal 900 46px/48px $acumin-pro-condensed;
        color: $white;
        text-shadow: 0 4px 250px $black;

        @include media('>=xlarge') {
            font-size: 80px;
            line-height: 82px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}
