.legal {
    &__wrap {
        padding: 40px 0;

        @include media('>=xlarge') {
            padding: 70px 0;
        }
    }

    &__square {
        position: relative;
        display: flex;

        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: $cl-prim-dark-blue;

            @include media('>=xlarge') {
                width: 9px;
                height: 9px;
            }
        }
    }

    &__square-p-left {
        &.static-page__text--legal {
            padding-left: 25px;

            @include media('>=xlarge') {
                padding-left: 30px;
            }
        }
    }

    &__spacing {
        padding-top: 35px;

        @include media('>=medium') {
            padding-top: 20px;
        }
    }

    .extra-mb {
        margin-bottom: 22px;

        @include media('>=xlarge') {
            margin-bottom: 32px;
        }
    }

    .table {
        margin-bottom: 30px;
        position: relative;
        background-color: $white;
        border: 3px solid $cl-sec-grey;
        border-top: none;

        @include media('>=medium') {
            border-bottom: none;
        }

        @include media('>=xlarge') {
            border-bottom: 3px solid $cl-sec-grey;
        }

        .padded-right {
            @include media('>=medium') {
                padding-right: 27px;
            }

            @include media('>=xlarge') {
                padding-right: 0;
            }
        }

        .padded-left {
            @include media('>=medium') {
                padding-left: 27px;
            }

            @include media('>=xlarge') {
                padding-left: 0;
            }
        }

        .cell {
            @include media('>=xlarge') {
                border-right: 3px solid $cl-sec-grey;

                &.last {
                    border-right: none;
                }
            }
        }

        &__wrap {
            height: 100%;
            border-bottom: 3px solid $cl-sec-grey;

            &.last {
                border-bottom: none;
            }

            @include media('>=xlarge') {
                border-bottom: none;
            }
        }

        &__header {
            background-color: $cl-sec-grey;
            padding-bottom: 7px;

            @include media('>=xlarge') {
                padding-bottom: 35px;
            }

            .static-page__text {
                margin-bottom: 0;
                font-weight: 600;
                border: none;
            }
        }

        .static-page__text {
            padding: 20px 20px 10px 20px;
            border-bottom: 3px solid $cl-sec-grey;

            &.legal__square {
                padding-left: 26px;

                @include media('>=xlarge') {
                    padding-left: 30px;
                }
            }

            &.p-b-none {
                padding: 0 5px 0 26px;
                margin: 0 0 0 15px;
                border: none;

                @include media('>=xlarge') {
                    padding-left: 30px;
                    margin: 0 0 0 20px;
                }
            }

            &.b-none {
                padding: 0 5px 0 26px;
                margin: 0 0 15px 20px;
                border: none;

                @include media('>=xlarge') {
                    padding-left: 30px;
                    margin: 0 0 20px 20px;
                }
            }

            &.ml-20 {
                margin-left: 20px;
            }

            &.mb-reduced {
                margin-bottom: 20px;
            }
        }

        &__section {
            background-color: $white;

            @include media('>=xlarge') {
                &:not(:last-child) {
                    padding-bottom: 2px;
                }
            }

            .static-page__text {
                padding: 0 20px;
                margin-top: 20px;
                border: none;
            }

            &.bordered {
                @include media('>=xlarge') {
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 3px;
                        background-color: $cl-sec-grey;
                    }
                }
            }

            &.sect-line {
                @include media('<=xlarge') {
                    border-bottom: 3px solid $cl-sec-grey;
                }
            }

            &.pb {
                padding-bottom: 14px;

                @include media('>=xlarge') {
                    padding-bottom: 0;
                }
            }
        }

        .bordered-right {
            @include media('>=medium') {
                border-right: 3px solid $cl-sec-grey;
            }

            @include media('>=xlarge') {
                border-right: none;
            }
        }

        .bordered-left {
            @include media('>=medium') {
                border-left: 3px solid $cl-sec-grey;
            }

            @include media('>=xlarge') {
                border-left: none;
            }
        }
    }
}
