@keyframes aob-hero-banner-static__reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.aob-hero-banner-static {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    @include media('>=medium') {
        padding: 0 34px;
    }

    @include media('>=large') {
        padding: 0 40px;

        @include ie-only {
            background-color: $cl-prim-dark-blue;
            z-index: 11;
        }
    }

    @include media('>=xlarge') {
        padding: 0 40px;
    }

    &__content {
        width: 100%;
        opacity: 0;
        animation: 600ms ease-in 0s 1 aob-hero-banner-static__reveal;
        animation-fill-mode: forwards;

        @include media('>=large') {
            max-width: 70%;
            max-height: 60vh;
        }
    }

    &__bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        background: linear-gradient(0deg, rgba($cl-sec-black, 1) 0%, rgba($cl-sec-black, 0) 100%);

        @include media('>=medium') {
            padding: 0 34px;
        }

        @include media('>=large') {
            flex-direction: row;
            align-items: flex-end;
            padding: 0 40px;
        }

        @include media('>=xlarge') {
            padding: 0 90px;
        }
    }

    &__bottom-nav {
        order: 1;
        overflow: visible !important;

        @include media('>=large') {
            order: 0;
            width: 100%;
        }

        .scroll-content {
            display: flex;
        }

        .scrollbar-track {
            display: none !important;
        }
    }

    &__bottom-nav-delimiter {
        order: 2;
        border-bottom: 3px solid rgba($cl-sec-white, 0.2);

        @include media('>=large') {
            display: none;
        }
    }

    &__bottom-nav-inner {
        width: 100%;
        display: flex;

        @include media('>=medium') {
            justify-content: center;
        }
    }

    &__bottom-nav-item {
        margin-bottom: 30px;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $cl-sec-white;
        white-space: nowrap;
        transition: color $animation-duration-default;

        @include media('>=medium') {
            margin-bottom: 20px;
        }

        @include media('>=large') {
            flex-basis: calc(100% / 6);
            margin-bottom: 30px;
            border-top: 3px solid rgba($cl-sec-white, 0.2);
            padding-top: 30px;
            text-align: left;
        }

        @include media('>=xlarge') {
            margin-bottom: 40px;
            font-size: 18px;
            line-height: 22px;
        }

        &:hover {
            @include media('>=large') {
                border-top-color: $cl-prim-light-blue;
                font-weight: bold;
            }
        }

        & + & {
            margin-left: 20px;

            @include media('>=large') {
                margin-left: 5px;
            }

            @include media('>=xlarge') {
                margin-left: 10px;
            }
        }
    }

    &__stock {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;

        @include media('>=medium') {
            max-width: 38%;
            margin-left: auto;
        }

        @include media('>=large') {
            margin-left: 40px;
            margin-bottom: 30px;
        }

        @include media('>=xlarge') {
            margin-left: 100px;
            margin-bottom: 40px;
        }
    }

    &__stock-date {
        flex-basis: 100%;
        margin-bottom: $vs-10;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__stock-variation {
        width: 70%;
        margin-bottom: 7px;
        white-space: nowrap;
        font: 900 30px/32px $acumin-pro-condensed;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__stock-variation-middot {
        @include ie-only {
            margin-left: 6px;
        }
    }

    &__stock-variation-value {
        display: inline-block;
        border-bottom: 2px solid $cl-sec-white;
        padding-bottom: 5px;
    }

    &__stock-id {
        width: 70%;
        white-space: nowrap;
        font: normal 12px/17px $acumin-pro;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__stock-price {
        max-width: 30%;
        white-space: nowrap;
        margin-bottom: 7px;
        border-bottom: 2px solid $cl-sec-white;
        padding-bottom: 5px;
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__stock-currency {
        font: normal 12px/17px $acumin-pro;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
