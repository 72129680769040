.aob-social-link {
    font-size: 30px;

    &,
    &:visited {
        color: $cl-sec-white;
        text-decoration: none;
    }

    &:hover {
        color: $cl-prim-light-blue;
    }

    & + & {
        margin-left: 30px;
    }

    &::before {
        margin: 0;
    }
}
