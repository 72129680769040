.static-page-global-wrap {
    color: $black;
    background-color: $white;
}

.static-page {
    &__title {
        padding: 55px 0;
        font: normal 900 30px/32px $acumin-pro-condensed;
        color: $black;

        @include media('>=xlarge') {
            padding: 90px 0;
            font-size: 52px;
            line-height: 57px;
        }

        &--inventors {
            @include media('>=xlarge') {
                padding: 90px 0 50px;
            }
        }
    }

    &__subsect {
        font: normal 900 16px/19px $acumin-pro-condensed;
        color: $cl-prim-dark-blue;
        letter-spacing: 0.06em;
        margin-bottom: 35px;

        @include media('>=xlarge') {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 45px;
        }
    }

    &__text {
        margin-bottom: $vs-20;
        font: normal normal 12px/17px $acumin-pro;
        color: $cl-prim-dark-blue;

        @include media('>=xlarge') {
            margin-bottom: $vs-30;
            font-size: 16px;
            line-height: 25px;
        }

        &--legal {
            margin-bottom: 15px;

            @include media('>=xlarge') {
                margin-bottom: 25px;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    &__sect-vert-space {
        margin-bottom: $vs-50;

        @include media('>=medium') {
            margin-bottom: $vs-70;
        }

        &--legal {
            margin-bottom: $vs-50;

            @include media('>=medium') {
                margin-bottom: $vs-60;
            }
        }
    }

    &__subtitle {
        margin-bottom: $vs-30;
        font: normal 600 16px/23px $acumin-pro-condensed;
        letter-spacing: 0.02em;
        color: $cl-prim-dark-blue;
        text-transform: uppercase;

        @include media('>=medium') {
            margin-bottom: $vs-40;
        }

        @include media('>=xlarge') {
            font-size: 24px;
            line-height: 30px;
        }

        &--legal {
            text-transform: none;
            padding: 15px 0;
            margin: 0;

            @include media('>=xlarge') {
                font-size: 24px;
                line-height: 30px;
                padding: 25px 0;
            }

            &:first-child {
                padding: 17px 0;

                @include media('>=xlarge') {
                    padding: 25px 0 30px;
                }
            }
        }
    }
}

.inventors {
    &__wrap {
        padding-bottom: $vs-40;

        @include media('>=xlarge') {
            padding-bottom: $vs-60;
        }
    }

    &__list-item {
        display: flex;

        &:last-child {
            margin-bottom: 40px;

            @include media('>=xlarge') {
                margin-bottom: 30px;
            }
        }
    }

    &__num {
        padding-right: 15px;

        @include media('>=xlarge') {
            padding-right: 30px;
        }
    }
}
