// Global styling. Add cross-cutting classes here

// BEGIN ios overscroll fix
body {
    position: fixed;
    overflow: hidden;
}

.aob-wrapper {
    width: 100vw;
    max-height: 100vh;
    height: -webkit-fill-available; /* vh fix */
    overflow-y: auto;
    background-color: $cl-sec-black;
    color: $cl-sec-white;

    @include ie-only {
        overflow-x: hidden;
    }

    &--lock-scroll-y {
        overflow-y: hidden;

        @include media('>=large') {
            width: calc(100vw - 16px);
        }
    }
}
// END ios overscroll fix

// use both for <button> and <a> elements to gain corresponding styling
.aob-cta {
    margin: 0;
    border: none;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    text-transform: uppercase;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;

    &,
    &:visited {
        text-decoration: none;
    }

    &--primary {
        display: inline-block;
        padding: 15px;
        color: $cl-sec-black;
        background-color: $cl-prim-light-blue;

        &:hover,
        &:focus,
        &:active {
            color: $cl-sec-white;
            background-color: $cl-prim-mid-blue;
        }

        &:disabled {
            background-color: $cl-special-1;
        }
    }

    &--secondary {
        display: inline-block;
        border-bottom: 1px solid $cl-sec-black;
        padding-bottom: 10px;
        color: $cl-sec-black;

        &:hover,
        &:focus,
        &:active {
            border-bottom-color: $cl-prim-mid-blue;
            color: $cl-sec-black;
        }

        &:disabled {
            color: $cl-special-1;
            border-bottom-color: $cl-special-1;
        }
    }

    &--link-static-page {
        padding-bottom: 2px;
        border-bottom: 1px solid $cl-sec-black;
        text-transform: none;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            border-bottom-color: $cl-prim-mid-blue;
            color: $cl-sec-black;
        }

        &:disabled {
            color: $cl-special-1;
            border-bottom-color: $cl-special-1;
        }

        &-white {
            &:hover,
            &:focus,
            &:active {
                color: $cl-sec-white;
            }
        }
    }
}

.color-inherit {
    color: inherit;
}

.flex-wrap {
    flex-wrap: wrap;
}

.aob-sticky {
    position: -webkit-sticky;
    position: sticky;
}

.font-w-600 {
    font-weight: 600;
}

.text-color--primary-light-blue {
    color: $cl-prim-light-blue;
}
