.aob-footer-nav {
    @include media('>=large') {
        display: flex;
    }

    &__wrapper {
        margin-bottom: $vs-60;

        @include media('>=large') {
            order: 2;
            max-width: calc(50% - 20px);
            flex-basis: calc(50% - 20px);
            margin-bottom: 90px;
            padding-left: 20px;
        }

        @include media('>=xlarge') {
            max-width: calc(50% - 100px);
            flex-basis: calc(50% - 100px);
            display: flex;
            flex-direction: column;
            margin-bottom: $vs-200;
            padding-left: 100px;
        }
    }

    &__item {
        position: relative;
        border-bottom: 1px solid rgba($cl-sec-white, 0.4);

        @include media('>=large') {
            flex-basis: 33.3%;
            padding-right: 20px;
            border: none;
        }

        &:first-child {
            border-top: 1px solid rgba($cl-sec-white, 0.4);

            @include media('>=large') {
                border: none;
            }
        }

        &:last-child {
            @include media('>=large') {
                padding-right: 0;
            }
        }
    }

    &__item-title {
        width: 100%;
        margin: 20px 0;
        text-align: left;
        font: bold 12px/14px $acumin-pro-condensed;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        color: $cl-sec-white;
        cursor: default;

        @include media('>=large') {
            margin: 0 0 $vs-30;
        }

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }

        &::before {
            float: right;

            @include media('>=large') {
                display: none;
            }
        }
    }

    &__social-links {
        display: none;

        @include media('>=xlarge') {
            display: block;
            margin-top: auto;
        }
    }
}

.aob-footer-subnav {
    @include media('<large') {
        @include vertical-slide--collapsed;
    }

    &--expanded {
        @include media('<large') {
            @include vertical-slide--expanded;
        }
    }

    &__item {
        margin-bottom: 20px;
    }

    &__item-anchor {
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;

        &,
        &:visited {
            color: $cl-sec-white;
        }
    }
}
