/* stylelint-disable */
// setup
// $breakpoints variable is taken from './variables.scss';
$grid-dividers: (
    small: (12),
    medium: (12),
    large: (12),
    xlarge: (12)
);

$grid-margins: (
    small: 28px,
    medium: 34px,
    large: 40px,
    xlarge: 90px
);

// half values of what's in Figma designs
$cell-gutters: (
    small: 10px,
    medium: 10px,
    large: 20px,
    xlarge: 30px
);

// render class names
.grid-container {
    > [class^='grid-'] {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        > .cell {
            width: 100%;
            box-sizing: border-box;
        }
    }

    > .grid-y {
        flex-direction: column;
    }

    @each $grid-breakpoint-name, $grid-breakpoint-width in $breakpoints {
        @media (min-width: #{$grid-breakpoint-width}) {
            &.grid-margin-x {
                margin: 0 #{map_get($grid-margins, $grid-breakpoint-name)};
            }

            > [class^='grid-'] {
                &.grid-padding-x {
                    margin: 0 -#{map_get($cell-gutters, $grid-breakpoint-name)};

                    > .cell {
                        padding: 0 map_get($cell-gutters, $grid-breakpoint-name);
                    }
                }
            }

            @each $grid-divider in map-get($grid-dividers, $grid-breakpoint-name) {
                > .grid-#{$grid-breakpoint-name}-#{$grid-divider},
                > .grid-#{$grid-divider} {

                    @for $cell-span from 1 to $grid-divider + 1 {
                        > .cell.#{$grid-breakpoint-name}-#{$cell-span} {
                            width: 100% / $grid-divider * $cell-span;
                        }

                        > .#{$grid-breakpoint-name}-offset-#{$cell-span} {
                            margin-left: 100% / $grid-divider * $cell-span;
                        }
                    }
                }
            }
        }
    }
}
/* stylelint-enable */
