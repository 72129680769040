// Acumin pro
@font-face {
    font-family: 'acumin-pro';
    src:
        url('../fonts/acumin-pro/normal-400.woff2') format('woff2'),
        url('../fonts/acumin-pro/normal-400.woff') format('woff'),
        url('../fonts/acumin-pro/normal-400.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'acumin-pro';
    src:
        url('../fonts/acumin-pro/normal-500.woff2') format('woff2'),
        url('../fonts/acumin-pro/normal-500.woff') format('woff'),
        url('../fonts/acumin-pro/normal-500.otf') format('opentype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'acumin-pro';
    src:
        url('../fonts/acumin-pro/normal-600.woff2') format('woff2'),
        url('../fonts/acumin-pro/normal-600.woff') format('woff'),
        url('../fonts/acumin-pro/normal-600.otf') format('opentype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'acumin-pro';
    src:
        url('../fonts/acumin-pro/normal-900.woff2') format('woff2'),
        url('../fonts/acumin-pro/normal-900.woff') format('woff'),
        url('../fonts/acumin-pro/normal-900.otf') format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}

// Acumin pro condensed
@font-face {
    font-family: 'acumin-pro-condensed';
    src:
        url('../fonts/acumin-pro-condensed/normal-400.woff2') format('woff2'),
        url('../fonts/acumin-pro-condensed/normal-400.woff') format('woff'),
        url('../fonts/acumin-pro-condensed/normal-400.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'acumin-pro-condensed';
    src:
        url('../fonts/acumin-pro-condensed/normal-500.woff2') format('woff2'),
        url('../fonts/acumin-pro-condensed/normal-500.woff') format('woff'),
        url('../fonts/acumin-pro-condensed/normal-500.otf') format('opentype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'acumin-pro-condensed';
    src:
        url('../fonts/acumin-pro-condensed/normal-600.woff2') format('woff2'),
        url('../fonts/acumin-pro-condensed/normal-600.woff') format('woff'),
        url('../fonts/acumin-pro-condensed/normal-600.otf') format('opentype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'acumin-pro-condensed';
    src:
        url('../fonts/acumin-pro-condensed/normal-900.woff2') format('woff2'),
        url('../fonts/acumin-pro-condensed/normal-900.woff') format('woff'),
        url('../fonts/acumin-pro-condensed/normal-900.otf') format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}
