.collapsible {
    &__content {
        @include vertical-slide--collapsed;

        &--expanded {
            @include vertical-slide--expanded;
        }
    }

    &__item {
        border-bottom: 3px solid $cl-sec-grey;

        &:last-child {
            border-bottom: none;
        }
    }

    &__toggle {
        width: 100%;
        text-align: left;
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 18px;
            color: $black;
        }
    }
}
