.aob-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: map_get($header-height, 'small');
    padding: 0 20px;
    transition: background-color $animation-duration-default ease-in;
    transition-delay: 0.2s;

    @include media('>=medium') {
        height: map_get($header-height, 'medium');
        padding: 0 34px;
    }

    @include media('>=large') {
        height: map_get($header-height, 'large');
        padding: 0 40px;
    }

    @include media('>=xlarge') {
        height: map_get($header-height, 'xlarge');
        padding: 0 90px;
    }

    &--for-expanded-nav {
        background-color: $cl-sec-black;
        transition-delay: -0.2s;
        transition-timing-function: ease-in;
    }

    &__wrapper {
        position: relative;
        max-height: 0;
        overflow: visible;
        z-index: $zi-header;
    }

    &__logo-anchor {
        display: inline-block;
        width: 135px;
    }

    &__logo {
        width: 150px;

        @include media('>=large') {
            width: 225px;
        }
    }

    &__nav {
        @include media('>=large') {
            margin: 0 auto;
        }
    }
}

@import './headerNavigation';
