.right-text-over-img {
    position: relative;
    display: flex;
    padding: 40px 20px 100px;

    @include media('>=medium') {
        padding: 40px 34px 100px;
    }

    @include media('>=large') {
        padding: 40px 40px 100px;
    }

    @include media('>=xlarge') {
        padding: 40px 90px 100px;
    }

    &__background-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: none;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left center;
        box-shadow: inset -70vw 0 30vw -20vw black;

        @include media('>=large') {
            display: block;
        }
    }

    &__content {
        position: relative;

        @include media('>=large') {
            margin-left: auto;
            flex-basis: 45%;
            margin-right: 5%;
        }
    }

    &__title {
        margin-bottom: $vs-40;

        @include ie-only {
            max-height: 170px;
        }
    }

    &__brand-line {
        margin-top: $vs-40;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media('>=large') {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__text {
        font: normal 16px/1.4 $acumin-pro;

        @include media('>=xlarge') {
            font-size: 20px;
        }
    }

    &__delimiter {
        min-height: 70px;
        flex: 1 1 auto;
        margin: 35px auto;
        border-left: 1px solid $cl-prim-light-blue;

        @include media('>=large') {
            min-height: 0;
            margin: 0 35px;
            border-left: none;
            border-bottom: 1px solid $cl-prim-light-blue;
        }
    }

    &__brand-img-left,
    &__brand-img-right {
        width: 190px;

        @include media('>=large') {
            width: 30%;
        }
    }
}
