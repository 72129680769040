/* stylelint-disable */
// $breakpoints variable is taken from './variables.scss';
// produces classes
// .hid-from-{breakpoint name}
// .hid-to-{breakpoint name}

$num-breakpoints: length($breakpoints);

@mixin hidden-custom($type, $breakpoint) {
  .hid-#{$type}-#{$breakpoint} {
    display: none;
  }
}

@for $i from 1 through $num-breakpoints {
  $breakpoint: nth($breakpoints, $i);
  $breakpoint-name: nth($breakpoint, 1);

  // For
  @if $i == $num-breakpoints {
    @include media('>=#{$breakpoint-name}') {
      @include hidden-custom('for', $breakpoint-name);
    }
  } @else {
    $next-breakpoint: nth($breakpoints, $i + 1);
    $next-breakpoint-name: nth($next-breakpoint, 1);

    @include media('>=#{$breakpoint-name}', '<#{$next-breakpoint-name}') {
      @include hidden-custom('for', $breakpoint-name);
    }
  }

  // From
  @include media('>=#{$breakpoint-name}') {
    @include hidden-custom('from', $breakpoint-name);
  }

  // To
  @include media('<#{$breakpoint-name}') {
    @include hidden-custom('to', $breakpoint-name);
  }
}
/* stylelint-enable */