.text-image-horizontal {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 40px 20px;
    font-family: $acumin-pro;

    @include media('>=medium') {
        padding: 40px 34px;
    }

    @include media('>=large') {
        padding: 40px 40px;
    }

    @include media('>=xlarge') {
        padding: 40px 90px;
    }

    &__text-content {
        @include media('>=large') {
            max-width: 55%;
            padding: 60px;
        }
    }

    &__text-title {
        font-size: 25px;
        text-align: center;
        line-height: 1.4;

        @include media('>=large') {
            text-align: left;
        }

        @include media('>=xlarge') {
            font-size: 40px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 1.4;

        @include media('>=xlarge') {
            font-size: 20px;
        }
    }

    &__image {
        display: none;

        @include media('>=large') {
            display: block;
            flex-basis: 45%;
            background: no-repeat center right/cover;
            box-shadow: inset 35vw 0 20vw -20vw black;
        }
    }
}
