/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@font-face {
  font-family: 'acumin-pro';
  src: url("../fonts/acumin-pro/normal-400.woff2") format("woff2"), url("../fonts/acumin-pro/normal-400.woff") format("woff"), url("../fonts/acumin-pro/normal-400.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'acumin-pro';
  src: url("../fonts/acumin-pro/normal-500.woff2") format("woff2"), url("../fonts/acumin-pro/normal-500.woff") format("woff"), url("../fonts/acumin-pro/normal-500.otf") format("opentype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'acumin-pro';
  src: url("../fonts/acumin-pro/normal-600.woff2") format("woff2"), url("../fonts/acumin-pro/normal-600.woff") format("woff"), url("../fonts/acumin-pro/normal-600.otf") format("opentype");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'acumin-pro';
  src: url("../fonts/acumin-pro/normal-900.woff2") format("woff2"), url("../fonts/acumin-pro/normal-900.woff") format("woff"), url("../fonts/acumin-pro/normal-900.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900; }

@font-face {
  font-family: 'acumin-pro-condensed';
  src: url("../fonts/acumin-pro-condensed/normal-400.woff2") format("woff2"), url("../fonts/acumin-pro-condensed/normal-400.woff") format("woff"), url("../fonts/acumin-pro-condensed/normal-400.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'acumin-pro-condensed';
  src: url("../fonts/acumin-pro-condensed/normal-500.woff2") format("woff2"), url("../fonts/acumin-pro-condensed/normal-500.woff") format("woff"), url("../fonts/acumin-pro-condensed/normal-500.otf") format("opentype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'acumin-pro-condensed';
  src: url("../fonts/acumin-pro-condensed/normal-600.woff2") format("woff2"), url("../fonts/acumin-pro-condensed/normal-600.woff") format("woff"), url("../fonts/acumin-pro-condensed/normal-600.otf") format("opentype");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'acumin-pro-condensed';
  src: url("../fonts/acumin-pro-condensed/normal-900.woff2") format("woff2"), url("../fonts/acumin-pro-condensed/normal-900.woff") format("woff"), url("../fonts/acumin-pro-condensed/normal-900.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
/* stylelint-disable */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* stylelint-enable */
section,
div,
p,
a {
  box-sizing: border-box; }

img {
  display: block; }

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6,
section,
div,
p,
a {
  -webkit-font-smoothing: subpixel-antialiased; }

b {
  font-weight: bold; }

/* stylelint-disable */
.grid-container > [class^='grid-'] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .grid-container > [class^='grid-'] > .cell {
    width: 100%;
    box-sizing: border-box; }

.grid-container > .grid-y {
  flex-direction: column; }

@media (min-width: 0px) {
  .grid-container.grid-margin-x {
    margin: 0 28px; }
  .grid-container > [class^='grid-'].grid-padding-x {
    margin: 0 -10px; }
    .grid-container > [class^='grid-'].grid-padding-x > .cell {
      padding: 0 10px; }
  .grid-container > .grid-small-12 > .cell.small-1,
  .grid-container > .grid-12 > .cell.small-1 {
    width: 8.33333%; }
  .grid-container > .grid-small-12 > .small-offset-1,
  .grid-container > .grid-12 > .small-offset-1 {
    margin-left: 8.33333%; }
  .grid-container > .grid-small-12 > .cell.small-2,
  .grid-container > .grid-12 > .cell.small-2 {
    width: 16.66667%; }
  .grid-container > .grid-small-12 > .small-offset-2,
  .grid-container > .grid-12 > .small-offset-2 {
    margin-left: 16.66667%; }
  .grid-container > .grid-small-12 > .cell.small-3,
  .grid-container > .grid-12 > .cell.small-3 {
    width: 25%; }
  .grid-container > .grid-small-12 > .small-offset-3,
  .grid-container > .grid-12 > .small-offset-3 {
    margin-left: 25%; }
  .grid-container > .grid-small-12 > .cell.small-4,
  .grid-container > .grid-12 > .cell.small-4 {
    width: 33.33333%; }
  .grid-container > .grid-small-12 > .small-offset-4,
  .grid-container > .grid-12 > .small-offset-4 {
    margin-left: 33.33333%; }
  .grid-container > .grid-small-12 > .cell.small-5,
  .grid-container > .grid-12 > .cell.small-5 {
    width: 41.66667%; }
  .grid-container > .grid-small-12 > .small-offset-5,
  .grid-container > .grid-12 > .small-offset-5 {
    margin-left: 41.66667%; }
  .grid-container > .grid-small-12 > .cell.small-6,
  .grid-container > .grid-12 > .cell.small-6 {
    width: 50%; }
  .grid-container > .grid-small-12 > .small-offset-6,
  .grid-container > .grid-12 > .small-offset-6 {
    margin-left: 50%; }
  .grid-container > .grid-small-12 > .cell.small-7,
  .grid-container > .grid-12 > .cell.small-7 {
    width: 58.33333%; }
  .grid-container > .grid-small-12 > .small-offset-7,
  .grid-container > .grid-12 > .small-offset-7 {
    margin-left: 58.33333%; }
  .grid-container > .grid-small-12 > .cell.small-8,
  .grid-container > .grid-12 > .cell.small-8 {
    width: 66.66667%; }
  .grid-container > .grid-small-12 > .small-offset-8,
  .grid-container > .grid-12 > .small-offset-8 {
    margin-left: 66.66667%; }
  .grid-container > .grid-small-12 > .cell.small-9,
  .grid-container > .grid-12 > .cell.small-9 {
    width: 75%; }
  .grid-container > .grid-small-12 > .small-offset-9,
  .grid-container > .grid-12 > .small-offset-9 {
    margin-left: 75%; }
  .grid-container > .grid-small-12 > .cell.small-10,
  .grid-container > .grid-12 > .cell.small-10 {
    width: 83.33333%; }
  .grid-container > .grid-small-12 > .small-offset-10,
  .grid-container > .grid-12 > .small-offset-10 {
    margin-left: 83.33333%; }
  .grid-container > .grid-small-12 > .cell.small-11,
  .grid-container > .grid-12 > .cell.small-11 {
    width: 91.66667%; }
  .grid-container > .grid-small-12 > .small-offset-11,
  .grid-container > .grid-12 > .small-offset-11 {
    margin-left: 91.66667%; }
  .grid-container > .grid-small-12 > .cell.small-12,
  .grid-container > .grid-12 > .cell.small-12 {
    width: 100%; }
  .grid-container > .grid-small-12 > .small-offset-12,
  .grid-container > .grid-12 > .small-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .grid-container.grid-margin-x {
    margin: 0 34px; }
  .grid-container > [class^='grid-'].grid-padding-x {
    margin: 0 -10px; }
    .grid-container > [class^='grid-'].grid-padding-x > .cell {
      padding: 0 10px; }
  .grid-container > .grid-medium-12 > .cell.medium-1,
  .grid-container > .grid-12 > .cell.medium-1 {
    width: 8.33333%; }
  .grid-container > .grid-medium-12 > .medium-offset-1,
  .grid-container > .grid-12 > .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-container > .grid-medium-12 > .cell.medium-2,
  .grid-container > .grid-12 > .cell.medium-2 {
    width: 16.66667%; }
  .grid-container > .grid-medium-12 > .medium-offset-2,
  .grid-container > .grid-12 > .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-container > .grid-medium-12 > .cell.medium-3,
  .grid-container > .grid-12 > .cell.medium-3 {
    width: 25%; }
  .grid-container > .grid-medium-12 > .medium-offset-3,
  .grid-container > .grid-12 > .medium-offset-3 {
    margin-left: 25%; }
  .grid-container > .grid-medium-12 > .cell.medium-4,
  .grid-container > .grid-12 > .cell.medium-4 {
    width: 33.33333%; }
  .grid-container > .grid-medium-12 > .medium-offset-4,
  .grid-container > .grid-12 > .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-container > .grid-medium-12 > .cell.medium-5,
  .grid-container > .grid-12 > .cell.medium-5 {
    width: 41.66667%; }
  .grid-container > .grid-medium-12 > .medium-offset-5,
  .grid-container > .grid-12 > .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-container > .grid-medium-12 > .cell.medium-6,
  .grid-container > .grid-12 > .cell.medium-6 {
    width: 50%; }
  .grid-container > .grid-medium-12 > .medium-offset-6,
  .grid-container > .grid-12 > .medium-offset-6 {
    margin-left: 50%; }
  .grid-container > .grid-medium-12 > .cell.medium-7,
  .grid-container > .grid-12 > .cell.medium-7 {
    width: 58.33333%; }
  .grid-container > .grid-medium-12 > .medium-offset-7,
  .grid-container > .grid-12 > .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-container > .grid-medium-12 > .cell.medium-8,
  .grid-container > .grid-12 > .cell.medium-8 {
    width: 66.66667%; }
  .grid-container > .grid-medium-12 > .medium-offset-8,
  .grid-container > .grid-12 > .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-container > .grid-medium-12 > .cell.medium-9,
  .grid-container > .grid-12 > .cell.medium-9 {
    width: 75%; }
  .grid-container > .grid-medium-12 > .medium-offset-9,
  .grid-container > .grid-12 > .medium-offset-9 {
    margin-left: 75%; }
  .grid-container > .grid-medium-12 > .cell.medium-10,
  .grid-container > .grid-12 > .cell.medium-10 {
    width: 83.33333%; }
  .grid-container > .grid-medium-12 > .medium-offset-10,
  .grid-container > .grid-12 > .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-container > .grid-medium-12 > .cell.medium-11,
  .grid-container > .grid-12 > .cell.medium-11 {
    width: 91.66667%; }
  .grid-container > .grid-medium-12 > .medium-offset-11,
  .grid-container > .grid-12 > .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-container > .grid-medium-12 > .cell.medium-12,
  .grid-container > .grid-12 > .cell.medium-12 {
    width: 100%; }
  .grid-container > .grid-medium-12 > .medium-offset-12,
  .grid-container > .grid-12 > .medium-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .grid-container.grid-margin-x {
    margin: 0 40px; }
  .grid-container > [class^='grid-'].grid-padding-x {
    margin: 0 -20px; }
    .grid-container > [class^='grid-'].grid-padding-x > .cell {
      padding: 0 20px; }
  .grid-container > .grid-large-12 > .cell.large-1,
  .grid-container > .grid-12 > .cell.large-1 {
    width: 8.33333%; }
  .grid-container > .grid-large-12 > .large-offset-1,
  .grid-container > .grid-12 > .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-container > .grid-large-12 > .cell.large-2,
  .grid-container > .grid-12 > .cell.large-2 {
    width: 16.66667%; }
  .grid-container > .grid-large-12 > .large-offset-2,
  .grid-container > .grid-12 > .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-container > .grid-large-12 > .cell.large-3,
  .grid-container > .grid-12 > .cell.large-3 {
    width: 25%; }
  .grid-container > .grid-large-12 > .large-offset-3,
  .grid-container > .grid-12 > .large-offset-3 {
    margin-left: 25%; }
  .grid-container > .grid-large-12 > .cell.large-4,
  .grid-container > .grid-12 > .cell.large-4 {
    width: 33.33333%; }
  .grid-container > .grid-large-12 > .large-offset-4,
  .grid-container > .grid-12 > .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-container > .grid-large-12 > .cell.large-5,
  .grid-container > .grid-12 > .cell.large-5 {
    width: 41.66667%; }
  .grid-container > .grid-large-12 > .large-offset-5,
  .grid-container > .grid-12 > .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-container > .grid-large-12 > .cell.large-6,
  .grid-container > .grid-12 > .cell.large-6 {
    width: 50%; }
  .grid-container > .grid-large-12 > .large-offset-6,
  .grid-container > .grid-12 > .large-offset-6 {
    margin-left: 50%; }
  .grid-container > .grid-large-12 > .cell.large-7,
  .grid-container > .grid-12 > .cell.large-7 {
    width: 58.33333%; }
  .grid-container > .grid-large-12 > .large-offset-7,
  .grid-container > .grid-12 > .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-container > .grid-large-12 > .cell.large-8,
  .grid-container > .grid-12 > .cell.large-8 {
    width: 66.66667%; }
  .grid-container > .grid-large-12 > .large-offset-8,
  .grid-container > .grid-12 > .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-container > .grid-large-12 > .cell.large-9,
  .grid-container > .grid-12 > .cell.large-9 {
    width: 75%; }
  .grid-container > .grid-large-12 > .large-offset-9,
  .grid-container > .grid-12 > .large-offset-9 {
    margin-left: 75%; }
  .grid-container > .grid-large-12 > .cell.large-10,
  .grid-container > .grid-12 > .cell.large-10 {
    width: 83.33333%; }
  .grid-container > .grid-large-12 > .large-offset-10,
  .grid-container > .grid-12 > .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-container > .grid-large-12 > .cell.large-11,
  .grid-container > .grid-12 > .cell.large-11 {
    width: 91.66667%; }
  .grid-container > .grid-large-12 > .large-offset-11,
  .grid-container > .grid-12 > .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-container > .grid-large-12 > .cell.large-12,
  .grid-container > .grid-12 > .cell.large-12 {
    width: 100%; }
  .grid-container > .grid-large-12 > .large-offset-12,
  .grid-container > .grid-12 > .large-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1680px) {
  .grid-container.grid-margin-x {
    margin: 0 90px; }
  .grid-container > [class^='grid-'].grid-padding-x {
    margin: 0 -30px; }
    .grid-container > [class^='grid-'].grid-padding-x > .cell {
      padding: 0 30px; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-1,
  .grid-container > .grid-12 > .cell.xlarge-1 {
    width: 8.33333%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-1,
  .grid-container > .grid-12 > .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-2,
  .grid-container > .grid-12 > .cell.xlarge-2 {
    width: 16.66667%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-2,
  .grid-container > .grid-12 > .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-3,
  .grid-container > .grid-12 > .cell.xlarge-3 {
    width: 25%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-3,
  .grid-container > .grid-12 > .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-4,
  .grid-container > .grid-12 > .cell.xlarge-4 {
    width: 33.33333%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-4,
  .grid-container > .grid-12 > .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-5,
  .grid-container > .grid-12 > .cell.xlarge-5 {
    width: 41.66667%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-5,
  .grid-container > .grid-12 > .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-6,
  .grid-container > .grid-12 > .cell.xlarge-6 {
    width: 50%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-6,
  .grid-container > .grid-12 > .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-7,
  .grid-container > .grid-12 > .cell.xlarge-7 {
    width: 58.33333%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-7,
  .grid-container > .grid-12 > .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-8,
  .grid-container > .grid-12 > .cell.xlarge-8 {
    width: 66.66667%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-8,
  .grid-container > .grid-12 > .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-9,
  .grid-container > .grid-12 > .cell.xlarge-9 {
    width: 75%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-9,
  .grid-container > .grid-12 > .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-10,
  .grid-container > .grid-12 > .cell.xlarge-10 {
    width: 83.33333%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-10,
  .grid-container > .grid-12 > .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-11,
  .grid-container > .grid-12 > .cell.xlarge-11 {
    width: 91.66667%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-11,
  .grid-container > .grid-12 > .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-container > .grid-xlarge-12 > .cell.xlarge-12,
  .grid-container > .grid-12 > .cell.xlarge-12 {
    width: 100%; }
  .grid-container > .grid-xlarge-12 > .xlarge-offset-12,
  .grid-container > .grid-12 > .xlarge-offset-12 {
    margin-left: 100%; } }

/* stylelint-enable */
/* stylelint-disable */
@media (min-width: 0px) and (max-width: 767px) {
  .hid-for-small {
    display: none; } }

@media (min-width: 0px) {
  .hid-from-small {
    display: none; } }

@media (max-width: -1px) {
  .hid-to-small {
    display: none; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hid-for-medium {
    display: none; } }

@media (min-width: 768px) {
  .hid-from-medium {
    display: none; } }

@media (max-width: 767px) {
  .hid-to-medium {
    display: none; } }

@media (min-width: 1024px) and (max-width: 1679px) {
  .hid-for-large {
    display: none; } }

@media (min-width: 1024px) {
  .hid-from-large {
    display: none; } }

@media (max-width: 1023px) {
  .hid-to-large {
    display: none; } }

@media (min-width: 1680px) {
  .hid-for-xlarge {
    display: none; } }

@media (min-width: 1680px) {
  .hid-from-xlarge {
    display: none; } }

@media (max-width: 1679px) {
  .hid-to-xlarge {
    display: none; } }

/* stylelint-enable */
body {
  position: fixed;
  overflow: hidden; }

.aob-wrapper {
  width: 100vw;
  max-height: 100vh;
  height: -webkit-fill-available;
  /* vh fix */
  overflow-y: auto;
  background-color: #000;
  color: #fff;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .aob-wrapper {
      overflow-x: hidden; } }
  .aob-wrapper--lock-scroll-y {
    overflow-y: hidden; }
    @media (min-width: 1024px) {
      .aob-wrapper--lock-scroll-y {
        width: calc(100vw - 16px); } }

.aob-cta {
  margin: 0;
  border: none;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  text-transform: uppercase;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s; }
  .aob-cta, .aob-cta:visited {
    text-decoration: none; }
  .aob-cta--primary {
    display: inline-block;
    padding: 15px;
    color: #000;
    background-color: #23ffff; }
    .aob-cta--primary:hover, .aob-cta--primary:focus, .aob-cta--primary:active {
      color: #fff;
      background-color: #27828c; }
    .aob-cta--primary:disabled {
      background-color: #e7e7e7; }
  .aob-cta--secondary {
    display: inline-block;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    color: #000; }
    .aob-cta--secondary:hover, .aob-cta--secondary:focus, .aob-cta--secondary:active {
      border-bottom-color: #27828c;
      color: #000; }
    .aob-cta--secondary:disabled {
      color: #e7e7e7;
      border-bottom-color: #e7e7e7; }
  .aob-cta--link-static-page {
    padding-bottom: 2px;
    border-bottom: 1px solid #000;
    text-transform: none;
    text-decoration: none; }
    .aob-cta--link-static-page:hover, .aob-cta--link-static-page:focus, .aob-cta--link-static-page:active {
      border-bottom-color: #27828c;
      color: #000; }
    .aob-cta--link-static-page:disabled {
      color: #e7e7e7;
      border-bottom-color: #e7e7e7; }
    .aob-cta--link-static-page-white:hover, .aob-cta--link-static-page-white:focus, .aob-cta--link-static-page-white:active {
      color: #fff; }

.color-inherit {
  color: inherit; }

.flex-wrap {
  flex-wrap: wrap; }

.aob-sticky {
  position: -webkit-sticky;
  position: sticky; }

.font-w-600 {
  font-weight: 600; }

.text-color--primary-light-blue {
  color: #23ffff; }

.aob-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 0 20px;
  transition: background-color 300ms ease-in;
  transition-delay: 0.2s; }
  @media (min-width: 768px) {
    .aob-header {
      height: 95px;
      padding: 0 34px; } }
  @media (min-width: 1024px) {
    .aob-header {
      height: 95px;
      padding: 0 40px; } }
  @media (min-width: 1680px) {
    .aob-header {
      height: 95px;
      padding: 0 90px; } }
  .aob-header--for-expanded-nav {
    background-color: #000;
    transition-delay: -0.2s;
    transition-timing-function: ease-in; }
  .aob-header__wrapper {
    position: relative;
    max-height: 0;
    overflow: visible;
    z-index: 100; }
  .aob-header__logo-anchor {
    display: inline-block;
    width: 135px; }
  .aob-header__logo {
    width: 150px; }
    @media (min-width: 1024px) {
      .aob-header__logo {
        width: 225px; } }
  @media (min-width: 1024px) {
    .aob-header__nav {
      margin: 0 auto; } }

.aob-nav__icon-mobile {
  font-size: 22px;
  color: #fff; }
  @media (min-width: 1024px) {
    .aob-nav__icon-mobile {
      display: none; } }
  .aob-nav__icon-mobile::before {
    margin: -10px;
    padding: 10px; }

.aob-nav--expanded .aob-nav__toggle {
  max-height: calc(100vh - 75px); }
  @media (min-width: 768px) {
    .aob-nav--expanded .aob-nav__toggle {
      max-height: calc(100vh - 95px); } }
  @media (max-width: 1023px) {
    .aob-nav--expanded .aob-nav__toggle {
      overflow-y: auto;
      transition-timing-function: ease-in; } }
  @media (min-width: 1024px) {
    .aob-nav--expanded .aob-nav__toggle {
      max-height: none; } }

.aob-nav__toggle {
  position: fixed;
  top: 75px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000; }
  @media (max-width: 1023px) {
    .aob-nav__toggle {
      max-height: 0;
      overflow: hidden;
      will-change: max-height;
      transition: 300ms ease-out; } }
  @media (min-width: 768px) {
    .aob-nav__toggle {
      top: 95px; } }
  @media (min-width: 1024px) {
    .aob-nav__toggle {
      position: static;
      background-color: transparent; } }

.aob-nav__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px; }
  @media (min-width: 1024px) {
    .aob-nav__items {
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0; } }

.aob-nav__item + .aob-nav__item {
  margin-top: 20px; }
  @media (min-width: 1024px) {
    .aob-nav__item + .aob-nav__item {
      margin: 0; } }

.aob-nav__item-anchor {
  display: inline-block;
  padding: 10px;
  font: normal 12px/14px "acumin-pro-condensed";
  text-decoration: none; }
  @media (min-width: 1680px) {
    .aob-nav__item-anchor {
      padding: 15px;
      font-size: 18px;
      line-height: 22px; } }
  .aob-nav__item-anchor, .aob-nav__item-anchor:visited {
    color: #fff; }
  .aob-nav__item-anchor.active {
    font-weight: bold; }

.aob-footer {
  display: flex;
  flex-direction: column;
  padding: 50px 20px 30px;
  border-bottom: 5px solid #23ffff;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .aob-footer {
      padding: 50px 34px 30px; } }
  @media (min-width: 1024px) {
    .aob-footer {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 100px 120px; } }
  @media (min-width: 1680px) {
    .aob-footer {
      padding: 250px 220px 30px; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .aob-footer {
      position: relative;
      background-color: #000;
      z-index: 12; } }
  .aob-footer__brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    margin: 0 auto 60px; }
    @media (min-width: 1024px) {
      .aob-footer__brand-logo {
        display: none; } }
  .aob-footer__social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 30px; }
    @media (min-width: 1024px) {
      .aob-footer__social-links {
        justify-content: flex-start;
        flex-basis: 50%;
        order: 3; } }
    @media (min-width: 1680px) {
      .aob-footer__social-links {
        display: none; } }
  .aob-footer__copyright {
    font: normal 12px/14px "acumin-pro-condensed";
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.06em; }
    @media (min-width: 768px) {
      .aob-footer__copyright {
        max-width: 280px;
        margin: 0 auto; } }
    @media (min-width: 1024px) {
      .aob-footer__copyright {
        max-width: none;
        margin: 0;
        flex-basis: calc(50%);
        order: 4;
        padding-left: 20px;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-align: left;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .aob-footer__copyright {
      flex-basis: calc(50% - 20px); } }
    @media (min-width: 1680px) {
      .aob-footer__copyright {
        flex-basis: 100%;
        padding: 0;
        text-align: center; } }

.aob-email-signup {
  margin-bottom: 60px; }
  @media (max-width: 1023px) {
    .aob-email-signup {
      display: none; } }
  @media (min-width: 768px) {
    .aob-email-signup {
      margin-left: auto;
      margin-right: auto;
      max-width: 340px; } }
  @media (min-width: 1024px) {
    .aob-email-signup {
      order: 1;
      flex-basis: 50%;
      max-width: none;
      margin: 0 0 90px;
      padding-right: 100px;
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .aob-email-signup {
      flex-basis: calc(50% - 100px); } }
  @media (min-width: 1680px) {
    .aob-email-signup {
      margin-bottom: 200px;
      padding-right: 160px;
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 1680px) and (-ms-high-contrast: active), (min-width: 1680px) and (-ms-high-contrast: none) {
    .aob-email-signup {
      flex-basis: calc(50% - 160px); } }
  .aob-email-signup__title, .aob-email-signup__form {
    visibility: hidden; }
  .aob-email-signup__title {
    margin-bottom: 30px;
    font: 900 16px/19px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-align: center; }
    @media (min-width: 1024px) {
      .aob-email-signup__title {
        text-align: left; } }
    @media (min-width: 1680px) {
      .aob-email-signup__title {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 26px; } }
  .aob-email-signup__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(35, 255, 255, 0.9);
    padding-bottom: 20px; }
  .aob-email-signup__field, .aob-email-signup__cta {
    padding: 0;
    border: none;
    background-color: transparent;
    font: 500 13px/16px "acumin-pro-condensed";
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff; }
  .aob-email-signup__field::placeholder {
    color: #fff; }
  .aob-email-signup__logo {
    display: none; }
    @media (min-width: 1024px) {
      .aob-email-signup__logo {
        display: block;
        width: 160px;
        margin-bottom: 50px; } }
    @media (min-width: 1680px) {
      .aob-email-signup__logo {
        width: 332px;
        margin-bottom: 80px; } }

@media (min-width: 1024px) {
  .aob-footer-nav {
    display: flex; } }

.aob-footer-nav__wrapper {
  margin-bottom: 60px; }
  @media (min-width: 1024px) {
    .aob-footer-nav__wrapper {
      order: 2;
      max-width: calc(50% - 20px);
      flex-basis: calc(50% - 20px);
      margin-bottom: 90px;
      padding-left: 20px; } }
  @media (min-width: 1680px) {
    .aob-footer-nav__wrapper {
      max-width: calc(50% - 100px);
      flex-basis: calc(50% - 100px);
      display: flex;
      flex-direction: column;
      margin-bottom: 200px;
      padding-left: 100px; } }

.aob-footer-nav__item {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  @media (min-width: 1024px) {
    .aob-footer-nav__item {
      flex-basis: 33.3%;
      padding-right: 20px;
      border: none; } }
  .aob-footer-nav__item:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.4); }
    @media (min-width: 1024px) {
      .aob-footer-nav__item:first-child {
        border: none; } }
  @media (min-width: 1024px) {
    .aob-footer-nav__item:last-child {
      padding-right: 0; } }

.aob-footer-nav__item-title {
  width: 100%;
  margin: 20px 0;
  text-align: left;
  font: bold 12px/14px "acumin-pro-condensed";
  text-transform: uppercase;
  letter-spacing: 0.06em;
  color: #fff;
  cursor: default; }
  @media (min-width: 1024px) {
    .aob-footer-nav__item-title {
      margin: 0 0 30px; } }
  @media (min-width: 1680px) {
    .aob-footer-nav__item-title {
      font-size: 18px;
      line-height: 22px; } }
  .aob-footer-nav__item-title::before {
    float: right; }
    @media (min-width: 1024px) {
      .aob-footer-nav__item-title::before {
        display: none; } }

.aob-footer-nav__social-links {
  display: none; }
  @media (min-width: 1680px) {
    .aob-footer-nav__social-links {
      display: block;
      margin-top: auto; } }

@media (max-width: 1023px) {
  .aob-footer-subnav {
    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    transition: max-height 300ms ease-out; } }

@media (max-width: 1023px) {
  .aob-footer-subnav--expanded {
    max-height: 99999px;
    transition-timing-function: ease-in; } }

.aob-footer-subnav__item {
  margin-bottom: 20px; }

.aob-footer-subnav__item-anchor {
  font: 500 13px/16px "acumin-pro-condensed";
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap; }
  .aob-footer-subnav__item-anchor, .aob-footer-subnav__item-anchor:visited {
    color: #fff; }

.aob-social-link {
  font-size: 30px; }
  .aob-social-link, .aob-social-link:visited {
    color: #fff;
    text-decoration: none; }
  .aob-social-link:hover {
    color: #23ffff; }
  .aob-social-link + .aob-social-link {
    margin-left: 30px; }
  .aob-social-link::before {
    margin: 0; }

.page-hero {
  position: relative; }
  .page-hero__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: normal 900 46px/48px "acumin-pro-condensed";
    color: #fff;
    text-shadow: 0 4px 250px #000; }
    @media (min-width: 1680px) {
      .page-hero__title {
        font-size: 80px;
        line-height: 82px; } }
  .page-hero img {
    width: 100%;
    height: auto; }

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 300ms ease-out; }
  .collapsible__content--expanded {
    max-height: 99999px;
    transition-timing-function: ease-in; }

.collapsible__item {
  border-bottom: 3px solid #f2f2f2; }
  .collapsible__item:last-child {
    border-bottom: none; }

.collapsible__toggle {
  width: 100%;
  text-align: left;
  position: relative; }
  .collapsible__toggle::before {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 18px;
    color: #000; }

.news-global-wrapper {
  color: #000;
  background-color: #fff; }

.news__page-title {
  padding: 55px 0;
  font: normal 900 30px/32px "acumin-pro-condensed";
  letter-spacing: 1px; }
  @media (min-width: 1680px) {
    .news__page-title {
      padding: 90px 0;
      font-size: 52px;
      line-height: 57px; } }

.news__column {
  margin-bottom: 60px; }
  @media (min-width: 1024px) {
    .news__column {
      margin-bottom: 90px; } }
  @media (min-width: 1680px) {
    .news__column {
      margin-bottom: 100px; } }

.news .article {
  position: relative;
  height: 100%; }
  @media (min-width: 1680px) {
    .news .article {
      display: flex;
      height: auto; } }
  .news .article__info {
    margin-top: 15px; }
    @media (min-width: 1680px) {
      .news .article__info {
        margin-top: 0;
        justify-content: space-around;
        padding-bottom: 40px;
        margin-left: 20px;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 1680px) and (-ms-high-contrast: active), (min-width: 1680px) and (-ms-high-contrast: none) {
    .news .article__info {
      flex-basis: calc(100% / 11 * 6); } }
  .news .article__date {
    padding-top: 20px;
    font: normal normal 12px/14px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #6c777a; }
    @media (min-width: 1680px) {
      .news .article__date {
        display: block;
        padding-bottom: 20px;
        font-size: 18px;
        line-height: 22px; } }
  .news .article__title {
    padding: 10px 0 20px;
    font: normal 600 16px/23px "acumin-pro-condensed";
    letter-spacing: 0.02em; }
    @media (min-width: 768px) {
      .news .article__title {
        padding: 10px 0 50px; } }
    @media (min-width: 1680px) {
      .news .article__title {
        padding: 0;
        font-size: 24px;
        line-height: 30px; } }
  .news .article__link {
    align-self: end;
    font: normal 500 13px/16px "acumin-pro-condensed";
    color: inherit;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    text-decoration: none;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .news .article__link {
        position: absolute;
        bottom: 0; } }
    @media (min-width: 1680px) {
      .news .article__link {
        bottom: 10px; } }
    .news .article__link:hover, .news .article__link:focus, .news .article__link:active {
      border-bottom-color: #27828c;
      color: #000; }
    .news .article__link:disabled {
      color: #e7e7e7;
      border-bottom-color: #e7e7e7; }
  @media (max-width: 1679px) {
    .news .article img {
      max-width: 100%;
      width: 100%; } }
  .news .article__picture {
    /* stylelint-disable */
    /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active) and (min-width: 1680px), (-ms-high-contrast: none) and (min-width: 1680px) {
  .news .article__picture {
    flex-basis: calc(100% / 11 * 5); } }
  .news .article .object-fit-image {
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news .article .object-fit-image {
        width: 100%;
        background-repeat: no-repeat; } }
  @media screen and (-ms-high-contrast: active) and (min-width: 1680px), (-ms-high-contrast: none) and (min-width: 1680px) {
    .news .article .object-fit-image {
      height: 100%;
      background-size: cover;
      background-position: center; } }

.news__cta-view-more-wrap {
  padding: 15px 0 75px; }
  @media (min-width: 768px) {
    .news__cta-view-more-wrap {
      text-align: center; } }
  @media (min-width: 1024px) {
    .news__cta-view-more-wrap {
      padding: 15px 0 115px; } }

.news__cta-view-more {
  padding: 15px;
  font: normal 500 13px/16px "acumin-pro-condensed";
  background-color: #00101c;
  color: #fff;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none; }

.legal__wrap {
  padding: 40px 0; }
  @media (min-width: 1680px) {
    .legal__wrap {
      padding: 70px 0; } }

.legal__square {
  position: relative;
  display: flex; }
  .legal__square::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #00101c; }
    @media (min-width: 1680px) {
      .legal__square::before {
        width: 9px;
        height: 9px; } }

.legal__square-p-left.static-page__text--legal {
  padding-left: 25px; }
  @media (min-width: 1680px) {
    .legal__square-p-left.static-page__text--legal {
      padding-left: 30px; } }

.legal__spacing {
  padding-top: 35px; }
  @media (min-width: 768px) {
    .legal__spacing {
      padding-top: 20px; } }

.legal .extra-mb {
  margin-bottom: 22px; }
  @media (min-width: 1680px) {
    .legal .extra-mb {
      margin-bottom: 32px; } }

.legal .table {
  margin-bottom: 30px;
  position: relative;
  background-color: #fff;
  border: 3px solid #f2f2f2;
  border-top: none; }
  @media (min-width: 768px) {
    .legal .table {
      border-bottom: none; } }
  @media (min-width: 1680px) {
    .legal .table {
      border-bottom: 3px solid #f2f2f2; } }
  @media (min-width: 768px) {
    .legal .table .padded-right {
      padding-right: 27px; } }
  @media (min-width: 1680px) {
    .legal .table .padded-right {
      padding-right: 0; } }
  @media (min-width: 768px) {
    .legal .table .padded-left {
      padding-left: 27px; } }
  @media (min-width: 1680px) {
    .legal .table .padded-left {
      padding-left: 0; } }
  @media (min-width: 1680px) {
    .legal .table .cell {
      border-right: 3px solid #f2f2f2; }
      .legal .table .cell.last {
        border-right: none; } }
  .legal .table__wrap {
    height: 100%;
    border-bottom: 3px solid #f2f2f2; }
    .legal .table__wrap.last {
      border-bottom: none; }
    @media (min-width: 1680px) {
      .legal .table__wrap {
        border-bottom: none; } }
  .legal .table__header {
    background-color: #f2f2f2;
    padding-bottom: 7px; }
    @media (min-width: 1680px) {
      .legal .table__header {
        padding-bottom: 35px; } }
    .legal .table__header .static-page__text {
      margin-bottom: 0;
      font-weight: 600;
      border: none; }
  .legal .table .static-page__text {
    padding: 20px 20px 10px 20px;
    border-bottom: 3px solid #f2f2f2; }
    .legal .table .static-page__text.legal__square {
      padding-left: 26px; }
      @media (min-width: 1680px) {
        .legal .table .static-page__text.legal__square {
          padding-left: 30px; } }
    .legal .table .static-page__text.p-b-none {
      padding: 0 5px 0 26px;
      margin: 0 0 0 15px;
      border: none; }
      @media (min-width: 1680px) {
        .legal .table .static-page__text.p-b-none {
          padding-left: 30px;
          margin: 0 0 0 20px; } }
    .legal .table .static-page__text.b-none {
      padding: 0 5px 0 26px;
      margin: 0 0 15px 20px;
      border: none; }
      @media (min-width: 1680px) {
        .legal .table .static-page__text.b-none {
          padding-left: 30px;
          margin: 0 0 20px 20px; } }
    .legal .table .static-page__text.ml-20 {
      margin-left: 20px; }
    .legal .table .static-page__text.mb-reduced {
      margin-bottom: 20px; }
  .legal .table__section {
    background-color: #fff; }
    @media (min-width: 1680px) {
      .legal .table__section:not(:last-child) {
        padding-bottom: 2px; } }
    .legal .table__section .static-page__text {
      padding: 0 20px;
      margin-top: 20px;
      border: none; }
    @media (min-width: 1680px) {
      .legal .table__section.bordered::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: #f2f2f2; } }
    @media (max-width: 1680px) {
      .legal .table__section.sect-line {
        border-bottom: 3px solid #f2f2f2; } }
    .legal .table__section.pb {
      padding-bottom: 14px; }
      @media (min-width: 1680px) {
        .legal .table__section.pb {
          padding-bottom: 0; } }
  @media (min-width: 768px) {
    .legal .table .bordered-right {
      border-right: 3px solid #f2f2f2; } }
  @media (min-width: 1680px) {
    .legal .table .bordered-right {
      border-right: none; } }
  @media (min-width: 768px) {
    .legal .table .bordered-left {
      border-left: 3px solid #f2f2f2; } }
  @media (min-width: 1680px) {
    .legal .table .bordered-left {
      border-left: none; } }

.static-page-global-wrap {
  color: #000;
  background-color: #fff; }

.static-page__title {
  padding: 55px 0;
  font: normal 900 30px/32px "acumin-pro-condensed";
  color: #000; }
  @media (min-width: 1680px) {
    .static-page__title {
      padding: 90px 0;
      font-size: 52px;
      line-height: 57px; } }
  @media (min-width: 1680px) {
    .static-page__title--inventors {
      padding: 90px 0 50px; } }

.static-page__subsect {
  font: normal 900 16px/19px "acumin-pro-condensed";
  color: #00101c;
  letter-spacing: 0.06em;
  margin-bottom: 35px; }
  @media (min-width: 1680px) {
    .static-page__subsect {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 45px; } }

.static-page__text {
  margin-bottom: 20px;
  font: normal normal 12px/17px "acumin-pro";
  color: #00101c; }
  @media (min-width: 1680px) {
    .static-page__text {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 25px; } }
  .static-page__text--legal {
    margin-bottom: 15px; }
    @media (min-width: 1680px) {
      .static-page__text--legal {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 25px; } }

.static-page__sect-vert-space {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .static-page__sect-vert-space {
      margin-bottom: 70px; } }
  .static-page__sect-vert-space--legal {
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .static-page__sect-vert-space--legal {
        margin-bottom: 60px; } }

.static-page__subtitle {
  margin-bottom: 30px;
  font: normal 600 16px/23px "acumin-pro-condensed";
  letter-spacing: 0.02em;
  color: #00101c;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .static-page__subtitle {
      margin-bottom: 40px; } }
  @media (min-width: 1680px) {
    .static-page__subtitle {
      font-size: 24px;
      line-height: 30px; } }
  .static-page__subtitle--legal {
    text-transform: none;
    padding: 15px 0;
    margin: 0; }
    @media (min-width: 1680px) {
      .static-page__subtitle--legal {
        font-size: 24px;
        line-height: 30px;
        padding: 25px 0; } }
    .static-page__subtitle--legal:first-child {
      padding: 17px 0; }
      @media (min-width: 1680px) {
        .static-page__subtitle--legal:first-child {
          padding: 25px 0 30px; } }

.inventors__wrap {
  padding-bottom: 40px; }
  @media (min-width: 1680px) {
    .inventors__wrap {
      padding-bottom: 60px; } }

.inventors__list-item {
  display: flex; }
  .inventors__list-item:last-child {
    margin-bottom: 40px; }
    @media (min-width: 1680px) {
      .inventors__list-item:last-child {
        margin-bottom: 30px; } }

.inventors__num {
  padding-right: 15px; }
  @media (min-width: 1680px) {
    .inventors__num {
      padding-right: 30px; } }

.extra-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px 30px;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .extra-footer {
      padding: 50px 34px 30px;
      flex-wrap: nowrap; } }
  @media (min-width: 1024px) {
    .extra-footer {
      padding: 100px 120px; } }
  @media (min-width: 1680px) {
    .extra-footer {
      padding: 200px 220px 30px; } }
  .extra-footer__img-wrap {
    padding-right: 10px; }
    @media (max-width: 768px) {
      .extra-footer__img-wrap {
        display: flex;
        flex-basis: 33%;
        width: 33%;
        padding: 0 0 30px;
        align-items: flex-start; }
        .extra-footer__img-wrap.center {
          justify-content: center; }
        .extra-footer__img-wrap.end {
          justify-content: flex-end; } }
  .extra-footer__link-wrap {
    text-align: center; }
    @media (max-width: 768px) {
      .extra-footer__link-wrap {
        flex-basis: 100%;
        width: 100%; } }
    @media (min-width: 768px) {
      .extra-footer__link-wrap {
        align-self: baseline;
        margin-top: 8px; } }
  .extra-footer__link-brand {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    font: 500 13px/16px "acumin-pro-condensed";
    letter-spacing: 0.1em;
    color: #fff;
    text-decoration: none; }
  .extra-footer__img {
    width: 86px;
    max-width: 100%;
    height: auto; }
    @media (min-width: 768px) {
      .extra-footer__img {
        width: 70px; } }
    @media (min-width: 1024px) {
      .extra-footer__img {
        width: 86px; } }
    @media (min-width: 1680px) {
      .extra-footer__img {
        width: 128px; } }

.nasdaq-stock {
  opacity: 0;
  transition: 300ms; }
  .nasdaq-stock--populated {
    opacity: 1; }

.expandable-slider {
  margin-bottom: 100px;
  padding: 0 20px; }
  @media (min-width: 768px) {
    .expandable-slider {
      margin-bottom: 0;
      padding: 0 34px;
      display: flex;
      flex-direction: column; } }
  @media (min-width: 1024px) {
    .expandable-slider {
      padding: 0 40px; } }
  @media (min-width: 1680px) {
    .expandable-slider {
      padding: 0 90px; } }
  .expandable-slider__nav {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media (min-width: 768px) {
      .expandable-slider__nav {
        justify-content: space-between; } }
  .expandable-slider__nav-delimiter, .expandable-slider__nav-prev-cta, .expandable-slider__nav-next-cta {
    display: none; }
    @media (min-width: 768px) {
      .expandable-slider__nav-delimiter, .expandable-slider__nav-prev-cta, .expandable-slider__nav-next-cta {
        display: block; } }
  .expandable-slider__nav-prev-cta, .expandable-slider__nav-next-cta {
    color: #fff; }
    .expandable-slider__nav-prev-cta:disabled, .expandable-slider__nav-next-cta:disabled {
      color: #6c777a; }
  .expandable-slider__nav-next-cta {
    margin-left: 15px; }
  .expandable-slider__nav-text {
    text-align: center;
    font: 900 30px/32px "acumin-pro-condensed";
    letter-spacing: 1px;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .expandable-slider__nav-text {
        text-align: left;
        font: bold 12px/14px "acumin-pro-condensed";
        letter-spacing: 0.06em; } }
    @media (min-width: 1680px) {
      .expandable-slider__nav-text {
        font-size: 18px;
        line-height: 22px; } }
  .expandable-slider__nav-delimiter {
    flex-grow: 1;
    margin: 0 30px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.2); }
  .expandable-slider__slider {
    position: relative;
    transition: 300ms; }
    @media (min-width: 768px) {
      .expandable-slider__slider {
        margin: 0 -4px;
        width: calc(100% + 8px);
        height: calc(100vw / 768 * 358);
        display: flex;
        align-items: stretch;
        overflow: visible; } }
    @media (min-width: 1024px) {
      .expandable-slider__slider {
        height: calc(100vw / 1024 * 483); } }
    @media (min-width: 1680px) {
      .expandable-slider__slider {
        height: calc(100vw / 1680 * 768); } }
    @media (min-width: 768px) {
      .expandable-slider__slider--shift-left {
        transform: translateX(calc((-100vw + 34px * 2) / 4));
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .expandable-slider__slider--shift-left {
      left: calc((-100vw + 34px * 2) / 4); } }
    @media (min-width: 1024px) {
      .expandable-slider__slider--shift-left {
        transform: translateX(calc((-100vw + 40px * 2) / 4));
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .expandable-slider__slider--shift-left {
      left: calc((-100vw + 40px * 2) / 4); } }
    @media (min-width: 1680px) {
      .expandable-slider__slider--shift-left {
        transform: translateX(calc((-100vw + 90px * 2) / 4));
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 1680px) and (-ms-high-contrast: active), (min-width: 1680px) and (-ms-high-contrast: none) {
    .expandable-slider__slider--shift-left {
      left: calc((-100vw + 90px * 2) / 4); } }
  .expandable-slider__item + .expandable-slider__item {
    margin-top: 10px; }
    @media (min-width: 768px) {
      .expandable-slider__item + .expandable-slider__item {
        margin-top: 0; } }
  .expandable-slider .slick-track {
    display: flex; }

.expandable-slider-item {
  position: relative;
  background-color: #000;
  overflow: hidden;
  transition: 300ms;
  cursor: pointer;
  /* stylelint-disable */ }
  @media (min-width: 768px) {
    .expandable-slider-item {
      margin: 0 4px;
      min-width: calc(25% - 8px);
      flex-basis: calc(25% - 8px); } }
  .expandable-slider-item--expanded {
    min-height: 100vh;
    padding-bottom: 0;
    border-bottom: 3px solid #23ffff;
    display: flex;
    align-items: stretch; }
    @media (min-width: 768px) {
      .expandable-slider-item--expanded {
        min-height: 0;
        min-width: calc(50% - 8px);
        flex-basis: calc(50% - 8px);
        border-bottom: none; } }
    @media (min-width: 768px) {
      .expandable-slider-item--expanded .expandable-slider-item__hover-line {
        opacity: 1; } }
    .expandable-slider-item--expanded .expandable-slider-item__content {
      position: relative; }
      @media (min-width: 768px) {
        .expandable-slider-item--expanded .expandable-slider-item__content {
          flex-direction: column;
          padding: 40px 70px 40px 40px; } }
    .expandable-slider-item--expanded .expandable-slider-item__title {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 46px;
      line-height: 42px; }
      @media (min-width: 768px) {
        .expandable-slider-item--expanded .expandable-slider-item__title {
          margin-top: auto;
          writing-mode: lr;
          transform: none; } }
      @media (min-width: 1680px) {
        .expandable-slider-item--expanded .expandable-slider-item__title {
          font-size: 80px;
          line-height: 82px; } }
    @media (max-width: 767px) {
      .expandable-slider-item--expanded .expandable-slider-item__togglable {
        max-height: 100vh;
        transition-timing-function: ease-in;
        transition-duration: 300ms;
        transition-delay: 0s; } }
    @media (min-width: 768px) {
      .expandable-slider-item--expanded .expandable-slider-item__togglable {
        max-height: none;
        opacity: 1; } }
    .expandable-slider-item--expanded .expandable-slider-item__cta {
      order: 4;
      margin-top: auto;
      margin-left: auto;
      color: #fff;
      opacity: 1; }
      @media (min-width: 768px) {
        .expandable-slider-item--expanded .expandable-slider-item__cta {
          align-self: flex-start;
          margin-left: 0;
          transform: none; } }
      @media (min-width: 768px) {
        .expandable-slider-item--expanded .expandable-slider-item__cta span {
          writing-mode: lr; } }
      .expandable-slider-item--expanded .expandable-slider-item__cta .icon-plus,
      .expandable-slider-item--expanded .expandable-slider-item__cta .icon-minus {
        margin-left: 10px; }
    .expandable-slider-item--expanded .expandable-slider-item__cta-collapsed-text {
      display: none; }
    .expandable-slider-item--expanded .expandable-slider-item__cta-expanded-text {
      display: inline; }
  @media (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .expandable-slider-item {
      /* stylelint-enaable */ } }
  @media (hover: hover) and (min-width: 1024px), (-ms-high-contrast: active) and (min-width: 1024px), (-ms-high-contrast: none) and (min-width: 1024px) {
    .expandable-slider-item:hover .expandable-slider-item__hover-line {
      opacity: 1; } }
  @media (hover: hover) and (min-width: 1024px), (-ms-high-contrast: active) and (min-width: 1024px), (-ms-high-contrast: none) and (min-width: 1024px) {
    .expandable-slider-item:hover .expandable-slider-item__cta, .expandable-slider-item:hover .expandable-slider-item__title {
      color: #23ffff; } }
  @media (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .expandable-slider-item:hover .expandable-slider-item__cta {
        opacity: 1; } }
  .expandable-slider-item__hover-line {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: none;
    width: 3px;
    background-color: #23ffff;
    opacity: 0;
    transition: opacity 300ms; }
    @media (min-width: 768px) {
      .expandable-slider-item__hover-line {
        display: block; } }
  .expandable-slider-item__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 230px 10px 20px; }
    @media (min-width: 768px) {
      .expandable-slider-item__content {
        height: 100%;
        align-items: flex-start;
        padding: 20px 10px;
        overflow: hidden; } }
    @media (min-width: 1024px) {
      .expandable-slider-item__content {
        padding: 20px; } }
    @media (min-width: 1680px) {
      .expandable-slider-item__content {
        padding: 40px; } }
  .expandable-slider-item__background-img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.75;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .expandable-slider-item__background-img {
        width: auto; } }
  .expandable-slider-item__title {
    order: 1;
    max-width: 60%;
    font: 900 30px/32px "acumin-pro-condensed";
    letter-spacing: 0.01em;
    text-transform: uppercase;
    transition: font-size 300ms, color 300ms; }
    @media (min-width: 768px) {
      .expandable-slider-item__title {
        max-width: none;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        transition: font-size 0s, color 300ms;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .expandable-slider-item__title {
      margin-right: auto;
      transform: rotate(270deg) translateX(-100%);
      transform-origin: top left; } }
    @media (min-width: 1680px) {
      .expandable-slider-item__title {
        font-size: 52px;
        line-height: 57px; } }
  .expandable-slider-item__togglable {
    order: 3;
    transition-duration: 100ms;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (max-width: 767px) {
      .expandable-slider-item__togglable {
        max-height: 0;
        overflow: hidden;
        will-change: max-height;
        transition: max-height 300ms ease-out;
        transition-delay: -0.2s; } }
    @media (min-width: 768px) {
      .expandable-slider-item__togglable {
        max-height: 0;
        min-width: calc((100vw - 34px * 2) / 2 - 70px - 40px);
        opacity: 0;
        transition-duration: 0s; } }
    @media (min-width: 1024px) {
      .expandable-slider-item__togglable {
        min-width: calc((100vw - 40px * 2 ) / 2 - 70px - 40px); } }
    @media (min-width: 1680px) {
      .expandable-slider-item__togglable {
        min-width: calc((100vw - 90px * 2) / 2 - 70px - 40px); } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .expandable-slider-item__togglable {
        max-width: 100%; } }
  .expandable-slider-item__description {
    margin-bottom: 35px;
    font: normal 12px/17px "acumin-pro"; }
    @media (min-width: 1680px) {
      .expandable-slider-item__description {
        font-size: 16px;
        line-height: 25px; } }
  .expandable-slider-item__cta {
    order: 2;
    max-width: 40%;
    font: normal 12px/14px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    color: #23ffff;
    transition: color 300ms; }
    @media (min-width: 768px) {
      .expandable-slider-item__cta {
        order: 3;
        align-self: flex-end;
        max-width: none;
        transform: rotate(180deg);
        transform-origin: center;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .expandable-slider-item__cta {
      margin-right: auto;
      transform: rotate(270deg);
      transform-origin: top left; } }
    @media (min-width: 1024px) {
      .expandable-slider-item__cta {
        opacity: 0; } }
    @media (min-width: 1680px) {
      .expandable-slider-item__cta {
        font-size: 18px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .expandable-slider-item__cta span {
        writing-mode: vertical-lr; } }
    .expandable-slider-item__cta .expandable-slider-item__cta-collapsed-text {
      /* stylelint-disable */
      /* stylelint-enable */ }
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          @media (min-width: 768px) {
            .expandable-slider-item__cta .expandable-slider-item__cta-collapsed-text {
              position: relative;
              right: 10px; } }
          @media (min-width: 1680px) {
            .expandable-slider-item__cta .expandable-slider-item__cta-collapsed-text {
              right: 14px; } } } }
    .expandable-slider-item__cta .icon-plus,
    .expandable-slider-item__cta .icon-minus {
      margin-left: 10px; }
      @media (min-width: 768px) {
        .expandable-slider-item__cta .icon-plus,
        .expandable-slider-item__cta .icon-minus {
          margin: 10px 0 0 0;
          /* stylelint-disable */
          /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .expandable-slider-item__cta .icon-plus,
    .expandable-slider-item__cta .icon-minus {
      margin-left: 10px; } }
  .expandable-slider-item__cta-expanded-text {
    display: none; }

@keyframes aob-hero-banner-static__reveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.aob-hero-banner-static {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px; }
  @media (min-width: 768px) {
    .aob-hero-banner-static {
      padding: 0 34px; } }
  @media (min-width: 1024px) {
    .aob-hero-banner-static {
      padding: 0 40px;
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .aob-hero-banner-static {
      background-color: #00101c;
      z-index: 11; } }
  @media (min-width: 1680px) {
    .aob-hero-banner-static {
      padding: 0 40px; } }
  .aob-hero-banner-static__content {
    width: 100%;
    opacity: 0;
    animation: 600ms ease-in 0s 1 aob-hero-banner-static__reveal;
    animation-fill-mode: forwards; }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__content {
        max-width: 70%;
        max-height: 60vh; } }
  .aob-hero-banner-static__bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%); }
    @media (min-width: 768px) {
      .aob-hero-banner-static__bottom {
        padding: 0 34px; } }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__bottom {
        flex-direction: row;
        align-items: flex-end;
        padding: 0 40px; } }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__bottom {
        padding: 0 90px; } }
  .aob-hero-banner-static__bottom-nav {
    order: 1;
    overflow: visible !important; }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__bottom-nav {
        order: 0;
        width: 100%; } }
    .aob-hero-banner-static__bottom-nav .scroll-content {
      display: flex; }
    .aob-hero-banner-static__bottom-nav .scrollbar-track {
      display: none !important; }
  .aob-hero-banner-static__bottom-nav-delimiter {
    order: 2;
    border-bottom: 3px solid rgba(255, 255, 255, 0.2); }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__bottom-nav-delimiter {
        display: none; } }
  .aob-hero-banner-static__bottom-nav-inner {
    width: 100%;
    display: flex; }
    @media (min-width: 768px) {
      .aob-hero-banner-static__bottom-nav-inner {
        justify-content: center; } }
  .aob-hero-banner-static__bottom-nav-item {
    margin-bottom: 30px;
    font: normal 12px/14px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;
    transition: color 300ms; }
    @media (min-width: 768px) {
      .aob-hero-banner-static__bottom-nav-item {
        margin-bottom: 20px; } }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__bottom-nav-item {
        flex-basis: calc(100% / 6);
        margin-bottom: 30px;
        border-top: 3px solid rgba(255, 255, 255, 0.2);
        padding-top: 30px;
        text-align: left; } }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__bottom-nav-item {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 22px; } }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__bottom-nav-item:hover {
        border-top-color: #23ffff;
        font-weight: bold; } }
    .aob-hero-banner-static__bottom-nav-item + .aob-hero-banner-static__bottom-nav-item {
      margin-left: 20px; }
      @media (min-width: 1024px) {
        .aob-hero-banner-static__bottom-nav-item + .aob-hero-banner-static__bottom-nav-item {
          margin-left: 5px; } }
      @media (min-width: 1680px) {
        .aob-hero-banner-static__bottom-nav-item + .aob-hero-banner-static__bottom-nav-item {
          margin-left: 10px; } }
  .aob-hero-banner-static__stock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px; }
    @media (min-width: 768px) {
      .aob-hero-banner-static__stock {
        max-width: 38%;
        margin-left: auto; } }
    @media (min-width: 1024px) {
      .aob-hero-banner-static__stock {
        margin-left: 40px;
        margin-bottom: 30px; } }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock {
        margin-left: 100px;
        margin-bottom: 40px; } }
  .aob-hero-banner-static__stock-date {
    flex-basis: 100%;
    margin-bottom: 10px;
    font: normal 12px/14px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    text-transform: uppercase; }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock-date {
        font-size: 18px;
        line-height: 22px; } }
  .aob-hero-banner-static__stock-variation {
    width: 70%;
    margin-bottom: 7px;
    white-space: nowrap;
    font: 900 30px/32px "acumin-pro-condensed";
    text-transform: uppercase;
    letter-spacing: 0.01em; }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock-variation {
        font-size: 52px;
        line-height: 57px; } }
  .aob-hero-banner-static__stock-variation-middot {
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .aob-hero-banner-static__stock-variation-middot {
        margin-left: 6px; } }
  .aob-hero-banner-static__stock-variation-value {
    display: inline-block;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px; }
  .aob-hero-banner-static__stock-id {
    width: 70%;
    white-space: nowrap;
    font: normal 12px/17px "acumin-pro";
    text-transform: uppercase; }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock-id {
        font-size: 14px;
        line-height: 20px; } }
  .aob-hero-banner-static__stock-price {
    max-width: 30%;
    white-space: nowrap;
    margin-bottom: 7px;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
    font: 900 30px/32px "acumin-pro-condensed";
    letter-spacing: 0.01em; }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock-price {
        font-size: 52px;
        line-height: 57px; } }
  .aob-hero-banner-static__stock-currency {
    font: normal 12px/17px "acumin-pro";
    text-transform: uppercase; }
    @media (min-width: 1680px) {
      .aob-hero-banner-static__stock-currency {
        font-size: 14px;
        line-height: 20px; } }

.brands-accordion {
  position: relative;
  padding: 0 20px;
  margin-bottom: 100px;
  background-color: #000;
  opacity: 0;
  transition: 300ms; }
  @media (min-width: 768px) {
    .brands-accordion {
      height: 100vh;
      padding: 0 34px;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      overflow: visible; } }
  @media (min-width: 1024px) {
    .brands-accordion {
      padding: 0 40px; } }
  @media (min-width: 1680px) {
    .brands-accordion {
      padding: 0 90px; } }
  .brands-accordion--revealed {
    opacity: 1; }
  @media (min-width: 768px) {
    .brands-accordion--shift-left {
      transform: translateX(calc((-100vw + 34px * 2) / 4));
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .brands-accordion--shift-left {
      left: calc((-100vw + 34px * 2) / 4); } }
  @media (min-width: 1024px) {
    .brands-accordion--shift-left {
      transform: translateX(calc((-100vw + 40px * 2) / 4));
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .brands-accordion--shift-left {
      left: calc((-100vw + 40px * 2) / 4); } }
  @media (min-width: 1680px) {
    .brands-accordion--shift-left {
      transform: translateX(calc((-100vw + 90px * 2) / 4));
      /* stylelint-disable */
      /* stylelint-enable */ } }
  @media screen and (min-width: 1680px) and (-ms-high-contrast: active), (min-width: 1680px) and (-ms-high-contrast: none) {
    .brands-accordion--shift-left {
      left: calc((-100vw + 90px * 2) / 4); } }
  .brands-accordion__item + .brands-accordion__item {
    margin-top: 10px; }
    @media (min-width: 768px) {
      .brands-accordion__item + .brands-accordion__item {
        margin-top: 0; } }

.brands-accordion-item {
  position: relative;
  min-height: calc(100vw - 40px);
  background-color: #000;
  overflow: hidden;
  cursor: pointer; }
  @media (min-width: 768px) {
    .brands-accordion-item {
      min-height: 0;
      transition: 300ms; } }
  .brands-accordion-item--expanded {
    padding-bottom: 0;
    border-bottom: 3px solid #23ffff; }
    @media (min-width: 768px) {
      .brands-accordion-item--expanded {
        min-width: 50% !important;
        flex-basis: 50% !important;
        border-bottom: none; } }
    @media (min-width: 768px) {
      .brands-accordion-item--expanded .brands-accordion-item__hover-line {
        opacity: 1; } }
    .brands-accordion-item--expanded .brands-accordion-item__content {
      position: relative; }
      @media (min-width: 768px) {
        .brands-accordion-item--expanded .brands-accordion-item__content {
          flex-direction: column;
          padding: 40px 70px 40px 40px; } }
    .brands-accordion-item--expanded .brands-accordion-item__title {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 46px;
      line-height: 42px; }
      @media (min-width: 768px) {
        .brands-accordion-item--expanded .brands-accordion-item__title {
          margin-top: auto;
          writing-mode: lr;
          transform: none; } }
      @media (min-width: 1680px) {
        .brands-accordion-item--expanded .brands-accordion-item__title {
          font-size: 80px;
          line-height: 82px; } }
    @media (max-width: 767px) {
      .brands-accordion-item--expanded .brands-accordion-item__togglable {
        max-height: 100vh;
        transition-timing-function: ease-in;
        transition-duration: 300ms;
        transition-delay: 0s; } }
    @media (min-width: 768px) {
      .brands-accordion-item--expanded .brands-accordion-item__togglable {
        margin-bottom: auto;
        max-height: none;
        opacity: 1; } }
    .brands-accordion-item--expanded .brands-accordion-item__cta {
      order: 4;
      margin-left: auto;
      color: #fff;
      opacity: 1; }
      @media (min-width: 768px) {
        .brands-accordion-item--expanded .brands-accordion-item__cta {
          align-self: flex-start;
          margin-left: 0;
          transform: none; } }
      @media (min-width: 768px) {
        .brands-accordion-item--expanded .brands-accordion-item__cta span {
          writing-mode: lr; } }
      .brands-accordion-item--expanded .brands-accordion-item__cta .icon-plus,
      .brands-accordion-item--expanded .brands-accordion-item__cta .icon-minus {
        margin-left: 10px; }
    .brands-accordion-item--expanded .brands-accordion-item__cta-collapsed-text {
      display: none; }
    .brands-accordion-item--expanded .brands-accordion-item__cta-expanded-text {
      display: inline; }
  @media (min-width: 1024px) {
    .brands-accordion-item:hover .brands-accordion-item__hover-line {
      opacity: 1; } }
  @media (min-width: 1024px) {
    .brands-accordion-item:hover .brands-accordion-item__cta, .brands-accordion-item:hover .brands-accordion-item__title {
      color: #23ffff; } }
  .brands-accordion-item:hover .brands-accordion-item__cta {
    opacity: 1; }
  .brands-accordion-item__hover-line {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: none;
    width: 3px;
    background-color: #23ffff;
    opacity: 0;
    transition: opacity 300ms; }
    @media (min-width: 768px) {
      .brands-accordion-item__hover-line {
        display: block; } }
  .brands-accordion-item__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 230px 10px 20px; }
    @media (min-width: 768px) {
      .brands-accordion-item__content {
        height: 100%;
        align-items: flex-start;
        padding: 20px 10px;
        overflow: hidden; } }
    @media (min-width: 1024px) {
      .brands-accordion-item__content {
        padding: 20px; } }
    @media (min-width: 1680px) {
      .brands-accordion-item__content {
        padding: 40px; } }
  .brands-accordion-item__background-img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-size: cover;
    background-position: center;
    opacity: 0.75; }
  .brands-accordion-item__title {
    order: 1;
    max-width: 60%;
    font: 900 30px/32px "acumin-pro-condensed";
    letter-spacing: 0.01em;
    text-transform: uppercase;
    transition: font-size 300ms, color 300ms; }
    @media (min-width: 768px) {
      .brands-accordion-item__title {
        max-width: none;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        transition: font-size 0s, color 300ms;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .brands-accordion-item__title {
      margin-right: auto;
      transform: rotate(270deg) translateX(-100%);
      transform-origin: top left; } }
    @media (min-width: 1680px) {
      .brands-accordion-item__title {
        font-size: 52px;
        line-height: 57px; } }
  .brands-accordion-item__togglable {
    order: 3;
    transition-duration: 100ms;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (max-width: 767px) {
      .brands-accordion-item__togglable {
        max-height: 0;
        overflow: hidden;
        will-change: max-height;
        transition: max-height 300ms ease-out;
        transition-delay: -0.2s; } }
    @media (min-width: 768px) {
      .brands-accordion-item__togglable {
        max-height: 0;
        min-width: calc((100vw - 34px * 2) / 2 - 70px - 40px);
        opacity: 0;
        transition-duration: 0s; } }
    @media (min-width: 1024px) {
      .brands-accordion-item__togglable {
        min-width: calc((100vw - 40px * 2 ) / 2 - 70px - 40px); } }
    @media (min-width: 1680px) {
      .brands-accordion-item__togglable {
        min-width: calc((100vw - 90px * 2) / 2 - 70px - 40px); } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .brands-accordion-item__togglable {
        max-width: 100%; } }
  .brands-accordion-item__brands {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -15px;
    margin-bottom: 100px;
    width: calc(100% + 30px); }
  .brands-accordion-item__brand {
    flex-basis: 33.3%;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 15px; }
    @media (min-width: 768px) {
      .brands-accordion-item__brand {
        flex-basis: 25%; } }
    .brands-accordion-item__brand--grow {
      flex-basis: 50%; }
    .brands-accordion-item__brand--shrink {
      flex-shrink: 1;
      /* stylelint-disable */
      /* stylelint-enable */ }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .brands-accordion-item__brand--shrink {
          flex-basis: auto; } }
  .brands-accordion-item__brand-img {
    width: 100%;
    max-height: 40px;
    object-fit: contain;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .brands-accordion-item__brand-img {
        max-width: 100%; } }
  .brands-accordion-item__description {
    margin-bottom: 35px;
    font: normal 12px/17px "acumin-pro"; }
    @media (min-width: 1680px) {
      .brands-accordion-item__description {
        font-size: 16px;
        line-height: 25px; } }
  .brands-accordion-item__cta {
    order: 2;
    max-width: 40%;
    font: normal 12px/14px "acumin-pro-condensed";
    letter-spacing: 0.06em;
    color: #23ffff;
    transition: color 300ms; }
    @media (min-width: 768px) {
      .brands-accordion-item__cta {
        order: 3;
        align-self: flex-end;
        max-width: none;
        transform: rotate(180deg);
        transform-origin: center;
        /* stylelint-disable */
        /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .brands-accordion-item__cta {
      margin-right: auto;
      transform: rotate(270deg);
      transform-origin: top left; } }
    @media (min-width: 1024px) {
      .brands-accordion-item__cta {
        opacity: 0; } }
    @media (min-width: 1680px) {
      .brands-accordion-item__cta {
        font-size: 18px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .brands-accordion-item__cta span {
        writing-mode: vertical-lr; } }
    .brands-accordion-item__cta .brands-accordion-item__cta-collapsed-text {
      /* stylelint-disable */
      /* stylelint-enable */ }
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          @media (min-width: 768px) {
            .brands-accordion-item__cta .brands-accordion-item__cta-collapsed-text {
              position: relative;
              right: 15px; } }
          @media (min-width: 1680px) {
            .brands-accordion-item__cta .brands-accordion-item__cta-collapsed-text {
              right: 25px; } } } }
    .brands-accordion-item__cta .icon-plus,
    .brands-accordion-item__cta .icon-minus {
      margin-left: 10px; }
      @media (min-width: 768px) {
        .brands-accordion-item__cta .icon-plus,
        .brands-accordion-item__cta .icon-minus {
          margin: 10px 0 0 0;
          /* stylelint-disable */
          /* stylelint-enable */ } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .brands-accordion-item__cta .icon-plus,
    .brands-accordion-item__cta .icon-minus {
      margin-left: 10px; } }
  .brands-accordion-item__cta-expanded-text {
    display: none; }

.text-image-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 40px 20px;
  font-family: "acumin-pro"; }
  @media (min-width: 768px) {
    .text-image-horizontal {
      padding: 40px 34px; } }
  @media (min-width: 1024px) {
    .text-image-horizontal {
      padding: 40px 40px; } }
  @media (min-width: 1680px) {
    .text-image-horizontal {
      padding: 40px 90px; } }
  @media (min-width: 1024px) {
    .text-image-horizontal__text-content {
      max-width: 55%;
      padding: 60px; } }
  .text-image-horizontal__text-title {
    font-size: 25px;
    text-align: center;
    line-height: 1.4; }
    @media (min-width: 1024px) {
      .text-image-horizontal__text-title {
        text-align: left; } }
    @media (min-width: 1680px) {
      .text-image-horizontal__text-title {
        font-size: 40px; } }
  .text-image-horizontal__text {
    font-size: 16px;
    line-height: 1.4; }
    @media (min-width: 1680px) {
      .text-image-horizontal__text {
        font-size: 20px; } }
  .text-image-horizontal__image {
    display: none; }
    @media (min-width: 1024px) {
      .text-image-horizontal__image {
        display: block;
        flex-basis: 45%;
        background: no-repeat center right/cover;
        box-shadow: inset 35vw 0 20vw -20vw black; } }

.right-text-over-img {
  position: relative;
  display: flex;
  padding: 40px 20px 100px; }
  @media (min-width: 768px) {
    .right-text-over-img {
      padding: 40px 34px 100px; } }
  @media (min-width: 1024px) {
    .right-text-over-img {
      padding: 40px 40px 100px; } }
  @media (min-width: 1680px) {
    .right-text-over-img {
      padding: 40px 90px 100px; } }
  .right-text-over-img__background-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    box-shadow: inset -70vw 0 30vw -20vw black; }
    @media (min-width: 1024px) {
      .right-text-over-img__background-image {
        display: block; } }
  .right-text-over-img__content {
    position: relative; }
    @media (min-width: 1024px) {
      .right-text-over-img__content {
        margin-left: auto;
        flex-basis: 45%;
        margin-right: 5%; } }
  .right-text-over-img__title {
    margin-bottom: 40px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .right-text-over-img__title {
        max-height: 170px; } }
  .right-text-over-img__brand-line {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 1024px) {
      .right-text-over-img__brand-line {
        flex-direction: row;
        justify-content: space-between; } }
  .right-text-over-img__text {
    font: normal 16px/1.4 "acumin-pro"; }
    @media (min-width: 1680px) {
      .right-text-over-img__text {
        font-size: 20px; } }
  .right-text-over-img__delimiter {
    min-height: 70px;
    flex: 1 1 auto;
    margin: 35px auto;
    border-left: 1px solid #23ffff; }
    @media (min-width: 1024px) {
      .right-text-over-img__delimiter {
        min-height: 0;
        margin: 0 35px;
        border-left: none;
        border-bottom: 1px solid #23ffff; } }
  .right-text-over-img__brand-img-left, .right-text-over-img__brand-img-right {
    width: 190px; }
    @media (min-width: 1024px) {
      .right-text-over-img__brand-img-left, .right-text-over-img__brand-img-right {
        width: 30%; } }
