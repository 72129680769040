.news-global-wrapper {
    color: $black;
    background-color: $white;
}

.news {
    &__page-title {
        padding: 55px 0;
        font: normal 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 1px;

        @include media('>=xlarge') {
            padding: 90px 0;
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__column {
        margin-bottom: $vs-60;

        @include media('>=large') {
            margin-bottom: $vs-90;
        }

        @include media('>=xlarge') {
            margin-bottom: $vs-100;
        }
    }

    .article {
        position: relative;
        height: 100%;

        @include media('>=xlarge') {
            display: flex;
            height: auto;
        }

        &__info {
            margin-top: 15px;

            @include media('>=xlarge') {
                margin-top: 0;
                justify-content: space-around;
                padding-bottom: 40px;
                margin-left: 20px;

                @include ie-only {
                    flex-basis: calc(100% / 11 * 6);
                }
            }
        }

        &__date {
            padding-top: 20px;
            font: normal normal 12px/14px $acumin-pro-condensed;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: $cl-prim-grey;

            @include media('>=xlarge') {
                display: block;
                padding-bottom: 20px;
                font-size: 18px;
                line-height: 22px;
            }
        }

        &__title {
            padding: 10px 0 20px;
            font: normal 600 16px/23px $acumin-pro-condensed;
            letter-spacing: 0.02em;

            @include media('>=medium') {
                padding: 10px 0 50px;
            }

            @include media('>=xlarge') {
                padding: 0;
                font-size: 24px;
                line-height: 30px;
            }
        }

        &__link {
            align-self: end;
            font: normal 500 13px/16px $acumin-pro-condensed;
            color: inherit;
            letter-spacing: 0.1em;
            padding-bottom: 10px;
            border-bottom: 1px solid $black;
            text-decoration: none;
            text-transform: uppercase;

            @include media('>=medium') {
                position: absolute;
                bottom: 0;
            }

            @include media('>=xlarge') {
                bottom: 10px;
            }

            &:hover,
            &:focus,
            &:active {
                border-bottom-color: $cl-prim-mid-blue;
                color: $cl-sec-black;
            }

            &:disabled {
                color: $cl-special-1;
                border-bottom-color: $cl-special-1;
            }
        }

        img {
            @include media('<xlarge') {
                max-width: 100%;
                width: 100%;
            }
        }

        &__picture {
            @include ie-only {
                @include media('>=xlarge') {
                    flex-basis: calc(100% / 11 * 5);
                }
            }
        }

        .object-fit-image {
            @include ie-only {
                width: 100%;
                background-repeat: no-repeat;

                @include media('>=xlarge') {
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }

    &__cta-view-more-wrap {
        padding: 15px 0 75px;

        @include media('>=medium') {
            text-align: center;
        }

        @include media('>=large') {
            padding: 15px 0 115px;
        }
    }

    &__cta-view-more {
        padding: 15px;
        font: normal 500 13px/16px $acumin-pro-condensed;
        background-color: $cl-prim-dark-blue;
        color: $white;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
    }
}
