// Global default elements styling (on top of CSS reset)
section,
div,
p,
a {
    box-sizing: border-box;
}

img {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
section,
div,
p,
a {
    -webkit-font-smoothing: subpixel-antialiased;
}

b {
    font-weight: bold;
}
