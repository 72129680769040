.aob-nav {
    &__icon-mobile {
        font-size: 22px;
        color: $cl-sec-white;

        @include media('>=large') {
            display: none;
        }

        &::before {
            // increase click area a bit
            margin: -10px;
            padding: 10px;
        }
    }

    &--expanded {
        .aob-nav__toggle {
            max-height: calc(100vh - #{map_get($header-height, 'small')});

            @include media('>=medium') {
                max-height: calc(100vh - #{map_get($header-height, 'medium')});
            }

            @include media('<large') {
                overflow-y: auto;
                transition-timing-function: ease-in;
            }

            @include media('>=large') {
                max-height: none;
            }
        }
    }

    &__toggle {
        position: fixed;
        top: map_get($header-height, 'small');
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $cl-sec-black;

        @include media('<large') {
            max-height: 0;
            overflow: hidden;
            will-change: max-height;
            transition: $animation-duration-default ease-out;
        }

        @include media('>=medium') {
            top: map_get($header-height, 'medium');
        }

        @include media('>=large') {
            position: static;
            background-color: transparent;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $vs-10 10px;

        @include media('>=large') {
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0;
        }
    }

    &__item {
        & + & {
            margin-top: $vs-20;

            @include media('>=large') {
                margin: 0;
            }
        }
    }

    &__item-anchor {
        display: inline-block;
        padding: 10px;
        font: normal 12px/14px $acumin-pro-condensed;
        text-decoration: none;

        @include media('>=xlarge') {
            padding: 15px;
            font-size: 18px;
            line-height: 22px;
        }

        &,
        &:visited {
            color: $cl-sec-white;
        }

        &.active {
            font-weight: bold;
        }
    }
}
