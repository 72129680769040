.extra-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px $vs-30;
    flex-wrap: wrap;

    @include media('>=medium') {
        padding: 50px 34px $vs-30;
        flex-wrap: nowrap;
    }

    @include media('>=large') {
        padding: $vs-100 120px;
    }

    @include media('>=xlarge') {
        padding: 200px 220px 30px;
    }

    &__img-wrap {
        padding-right: 10px;

        @include media('<=medium') {
            display: flex;
            flex-basis: 33%;
            width: 33%;
            padding: 0 0 $vs-30;
            align-items: flex-start;

            &.center {
                justify-content: center;
            }

            &.end {
                justify-content: flex-end;
            }
        }
    }

    &__link-wrap {
        text-align: center;

        @include media('<=medium') {
            flex-basis: 100%;
            width: 100%;
        }

        @include media('>=medium') {
            align-self: baseline;
            margin-top: 8px;
        }
    }

    &__link-brand {
        padding-bottom: 10px;
        border-bottom: 1px solid $cl-sec-white;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        color: $cl-sec-white;
        text-decoration: none;
    }

    &__img {
        width: 86px;
        max-width: 100%;
        height: auto;

        @include media('>=medium') {
            width: 70px;
        }

        @include media('>=large') {
            width: 86px;
        }

        @include media('>=xlarge') {
            width: 128px;
        }
    }
}
